import MolaMfpAPI from '../clients/MolaMfpAPI';

export default {
  state: {
    reportingData: [],
    reportingMessage: '',
    reportingDetailData: [],
    predictionData: [],
    predictionMessage: '',
    predictionDetailData: [],
    subscriberDetailData: [],
    subscriberMessage: '',
    predictionDetailDataByFight: []
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getReportingByEvent({commit}, payload){
        let res;
        try {
            const response = await MolaMfpAPI.getReportingByEvent(payload);
            res = response;
            commit('setState', {
                reportingData: res.data.results,
            });
          } catch (err) {
            console.log(err);
            commit('setState', {
                reportingMessage: res.message
            });
            return;
          }
    },
    async getReportingDetailByEvent({commit}, payload){
      let res;
      try {
          const response = await MolaMfpAPI.getReportingDetailByEvent(payload);
          res = response;
          commit('setState', {
            reportingDetailData: res.data.results,
          });
        } catch (err) {
          console.log(err);
          commit('setState', {
              reportingMessage: res.message
          });
          return;
        }
    },
    async getPredictionsDetailByEvent({commit}, payload){
      let res;
      try {
          const response = await MolaMfpAPI.getPredictionsDetailByEvent(payload);
          res = response;
          commit('setState', {
            predictionDetailData: res.data.results,
          });
        } catch (err) {
          console.log(err);
          commit('setState', {
              predictionMessage: res.message
          });
          return;
        }
    },
    async getPredictionDataByEventAndFightResult({commit}, payload){
      let res;
      try {
          const response = await MolaMfpAPI.getPredictionDataByEventAndFightResult(payload);
          res = response;
          commit('setState', {
            predictionDetailDataByFight: res.data.results,
          });
        } catch (err) {
          console.log(err);
          commit('setState', {
              predictionMessage: res.message
          });
          return;
        }
    },
    async getMolaSubscribers({commit}, payload){
      let res;
      try {
          const response = await MolaMfpAPI.getMolaSubscribers(payload);
          res = response;
          commit('setState', {
            subscriberDetailData: res.data.results,
          });
        } catch (err) {
          console.log(err);
          commit('setState', {
              subscriberMessage: res.message
          });
          return;
        }
    }
  },
  getters: {},
};

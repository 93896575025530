<template>
  <div class="container">
    <h1 style="color:white; text-align: center; padding: 10px; text-transform: uppercase">
      <v-typography class="" color="white" v-if="!blankTitle && getLanguage === 'en'">{{title.en}}</v-typography>
      <v-typography class="" color="white" v-if="!blankTitle && getLanguage === 'id'">{{title.id}}</v-typography>
      <v-typography class="" color="white" v-if="!blankTitle && getLanguage === 'it'">{{title.it}}</v-typography>
    </h1>
    <div class="custom-wrapper">
      <v-container style="padding: 0; margin: 0">
        <v-row>
          <v-col cols="12" sm="12"  md="6">
            <v-container>
              <v-row>
                 <v-col cols="12" style="color: #fecd38; text-align: center; width: 50%">
                  <v-typography v-if="getLanguage === 'en'" class="title" color="#fecd38">{{ specialPrizeTitle.en }}</v-typography>
                  <v-typography v-if="getLanguage === 'id'" class="title" color="#fecd38">{{ specialPrizeTitle.id }}</v-typography>
                  <v-typography v-if="getLanguage === 'it'" class="title" color="#fecd38">{{ specialPrizeTitle.it }}</v-typography>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="color: white;"
                >
                <v-img
                  :lazy-src="isMobileDevice ? prizeCatalogBannerMobileUrl : prizeCatalogBannerDesktopUrl"
                  :src="isMobileDevice ? prizeCatalogBannerMobileUrl : prizeCatalogBannerDesktopUrl"
                  alt="item image"
                  width="100%"
                  height="100%"
                  class="image"
                  @click="gotoLink(imageLink)"
                ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12"  md="6" style="color: white;">
            <v-container>
              <v-row>
                 <v-col cols="12" style="color: #fecd38; text-align: center">
                  <v-typography v-if="getLanguage === 'en'" class="title" color="#fecd38">{{ prizeCatalogTitle.en }}</v-typography>
                  <v-typography v-if="getLanguage === 'id'" class="title" color="#fecd38">{{ prizeCatalogTitle.id }}</v-typography>
                  <v-typography v-if="getLanguage === 'it'" class="title" color="#fecd38">{{ prizeCatalogTitle.it }}</v-typography>
                </v-col>
              </v-row>
          <v-row>
            <v-col cols="12"  style="color: white;"
            >
            <v-img
              :lazy-src="isMobileDevice ? specialPrizeBannerMobileUrl : specialPrizeBannerDesktopUrl"
              :src="isMobileDevice ? specialPrizeBannerMobileUrl : specialPrizeBannerDesktopUrl"
              alt="item image"
              width="100%"
              height="100%"
              class="image"
              @click="gotoLink(imageLink)"
            ></v-img>
            </v-col>
          </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {db} from '@/main';
export default {
  props: {
    isMobileDevice: Boolean
  },
  data(){
    return {
      specialPrizeBannerDesktopUrl: '',
      specialPrizeBannerMobileUrl: '',
      prizeCatalogBannerDesktopUrl: '',
      prizeCatalogBannerMobileUrl: '',
      specialPrizeTitle: '',
      prizeCatalogTitle: '',
      imageLink: '',
      title: '',
      blankTitle: false,
    }
  },
  methods: {
    async getImageUrl() {
      const snapshot = await db.collection('config').doc("reward_config").get();
      if(snapshot.exists){
        const obj = snapshot.data();
        this.specialPrizeBannerDesktopUrl = obj.specialPrizeBannerDesktop.url
        this.specialPrizeBannerMobileUrl = obj.specialPrizeBannerMobile.url
        this.prizeCatalogBannerDesktopUrl = obj.prizeCatalogBannerDesktop.url
        this.prizeCatalogBannerMobileUrl = obj.prizeCatalogBannerMobile.url
        this.specialPrizeTitle = obj.specialPrizeTitle
        this.prizeCatalogTitle = obj.prizeCatalogTitle
        this.imageLink = obj.imageUrlLink;
        this.title = obj.title;
        this.blankTitle = obj.blankTitle;
      }
    },
    gotoLink(link) {
      if (link === '') return;
      return window.open(link, "_blank");
    },
  },
  created(){
    this.getImageUrl()
  },
  computed: {
    getLanguage(){
      return this.$store.state.current_language
    },
    lang(){
      return this.$store.state.lang;
    },
  }
}
</script>

<style scoped>
.custom-wrapper {
  border: 10px solid white;
  border-radius: 50px;
  padding: 5px 10px 10px 20px;
  width: 85%;
  margin:  0 auto;
  margin-bottom: 20px;
}
.image {
  object-fit: cover; 
}
.title_text {
  font-weight: small;
  font-weight: 400;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
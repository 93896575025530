export const FAQ = [
    {
        "title": " Tentang SuperChallenge Fight Predictor",
        "questions": [
            {
                "q": "Siapa yang berhak untuk berpartisipasi dalam SuperChallenge Fight Predictor?",
                "a": "Tanpa memperhatikan usia Anda atau tingkat pengalaman Anda, jika Anda tertarik dengan MMA dan antusias untuk menguji kemampuan prediksi Anda, maka Anda diperkenankan untuk berpartisipasi."
            },
            {
                "q": "Aspek pertarungan apa saja yang dapat saya prediksi dalam SuperChallenge Fight Predictor?",
                "a": "Dalam SuperChallenge Fight Predictor, Anda memiliki kesempatan untuk membuat prediksi di seluruh liga MMA (UFC, Bellator, Cage Warriors). Secara khusus, Anda dapat memprediksi hal-hal berikut: hasil pemenang pertarungan, metode kemenangan (bagaimana pertarungan akan dimenangkan: KO/TKO, Keputusan, Submission), dan ronde (jumlah ronde hingga pertarungan berakhir)."
            },
            {
                "q": "Bagaimana sistem perhitungan poin dalam SuperChallenge Fight Predictor?",
                "a": "<div><ol><li>Jika Anda memprediksi hasil pertarungan, yaitu pemenang pertarungan secara benar, Anda akan menerima 3 poin.</li><li>Memprediksi dengan tepat metode kemenangan - apakah pertarungan berakhir dengan KO/TKO, Keputusan, atau Submission - akan menghasilkan 4 poin dikalikan dengan poin dasar hasil pemenang pertarungan (total 12 poin).</li><li>Jika Anda berhasil memprediksi jumlah ronde pertarungan, Anda akan menerima 3 poin dikalikan dengan poin dasar hasil pemenang pertarungan (total 9 poin).</li></ol><br><p>Jika Anda dengan akurat memprediksi ketiga aspek pertarungan - hasil, metode, dan ronde - Anda akan mendapatkan bonus tambahan 2 poin. Oleh karena itu, jika semua prediksi untuk pertarungan reguler benar, total poin Anda untuk pertarungan tersebut akan menjadi 24 poin dari prediksi ditambah 2 poin bonus, sehingga totalnya menjadi 26 poin.</p><br><p>Akan ada SATU pertarungan unggulan setiap minggunya di mana Anda dapat memperoleh poin empat kali lipat dibandingkan dengan pertarungan reguler. Jika Anda menebak semua aspek dari pertarungan unggulan ini dengan benar, poin Anda untuk pertarungan tersebut akan berlipat ganda dari 24 menjadi 96, dan kemudian Anda akan mendapatkan 2 poin tambahan untuk memprediksi semua aspek secara akurat - hasil, metode, dan ronde - sehingga total poin untuk pertarungan unggulan adalah 98 poin.</p></div>"
            },
            {
                "q": "Apa yang terjadi jika saya salah memprediksi hasil pemenang pertarungan?",
                "a": "Jika Anda salah memprediksi hasil pemenang pertarungan, Anda tidak akan mendapatkan poin apa pun untuk pertarungan tersebut, meskipun prediksi Anda tentang metode dan jumlah ronde benar."
            },
            {
                "q": "Apa yang terjadi pada prediksi saya jika pertarungan diubah atau dibatalkan?",
                "a": "Jika salah satu petarung diganti, prediksi Anda secara otomatis dibatalkan. Anda harus memasukkan prediksi baru jika terjadi perubahan. Namun, jika sebuah pertarungan dibatalkan, tidak ada poin yang diberikan. Anda bertanggung jawab untuk memantau perubahan jadwal dan menyesuaikan prediksi Anda sesuai kebutuhan."
            },
            {
                "q": "Apakah jenis langganan yang berbeda mendapatkan poin yang berbeda?",
                "a": "Ya, pengguna yang menjadi pelanggan berbayar Mola mendapatkan 'Poin Mingguan' selain Poin Mola. Di sisi lain, pelanggan gratis Mola dan pengguna non-Mola hanya dapat mengumpulkan 'Poin Mingguan'."
            },
            {
                "q": "Apa perbedaan antara ‘Poin Mingguan’ dan 'Poin Mola'?",
                "a": "'Poin Mingguan' mengacu pada poin yang Anda dapatkan ketika prediksi pertarungan Anda benar dalam seminggu tertentu. Namun, poin-poin ini tidak dapat dikonversi menjadi Poin Mola kecuali jika Anda adalah pelanggan berbayar Mola. Bagi pelanggan berbayar, 'Poin Mingguan' akan otomatis dikonversi menjadi Poin Mola."
            },
            {
                "q": "Pada saat saya menjadi 'Pelanggan Gratis Mola', saya telah mengumpulkan ‘Poin Mingguan’. Apakah poin-poin ini akan terkonversi menjadi 'Poin Mola' saat saya beralih menjadi 'Pelanggan Berbayar Mola'?",
                "a": "Tidak, 'Poin Mingguan' yang telah Anda kumpulkan selama menjadi 'Pelanggan Gratis Mola' atau 'Pengguna Non-Mola' tidak akan terkonversi menjadi 'Poin Mola'."
            },
            {
                "q": "Jika saya menjadi pelanggan berbayar Mola setelah memasukan prediksi sebagai pelanggan gratis, apakah saya masih bisa mendapatkan Poin Mola?",
                "a": "Tidak, Anda tidak akan mendapatkan “Poin Mola” di minggu tersebut. Untuk bisa mendapatkan 'Poin Mola', Anda harus menjadi pelanggan berbayar sebelum memasukan prediksi. Namun, Anda bisa mendapatkan 'Poin Mola' di minggu-minggu berikutnya asalkan status langganan berbayar Anda masih aktif."
            },
            {
                "q": "Bagaimana cara melacak perkembangan dan peringkat saya dalam SuperChallenge Fight Predictor?",
                "a": "Buka opsi ‘Papan Peringkat’ di halaman utama untuk melacak peringkat mingguan dan bulanan Anda."
            },
            {
                "q": "Saya adalah peringkat tertinggi papan peringkat mingguan, tapi mengapa bukan saya pemenang Weekly Prize/Top 10 Finish?",
                "a": "Untuk dapat menjadi pemenang Weekly Prize / Top 10 Finish, Anda harus melakukan prediksi dan terdaftar menjadi pelanggan berbayar Mola sebagai syarat pemenang. Jangan lewatkan kesempatan memenangkan hadiah, berlangganan sekarang!"
            },
            {
                "q": "Apa yang dimaksud dengan fitur ‘Duel dengan Temanmu’ atau ‘Duel dengan Pemain Acak’?",
                "a": "Fitur ‘Duel dengan Temanmu’ adalah fitur di mana Anda dapat menantang teman Anda untuk mempertaruhkan seluruh Poin Mola yang diperoleh dari hasil prediksi yang dilakukan pada minggu tersebut. Pemenang duel akan ditentukan berdasarkan jumlah poin hasil prediksi tertinggi dan akan merebut semua poin dari lawan duelnya. Anda hanya dapat mengajak duel 1 (satu) lawan duel pada setiap minggunya, dimulai dari waktu prediksi dibuka hingga waktu prediksi ditutup. Setelah event berakhir, Anda akan mendapatkan pemberitahuan hasil duel yang Anda lakukan, pada halaman notifikasi. Fitur duel ini hanya dapat digunakan oleh pelanggan berbayar Mola."
            },
            {
                "q": "Bagaimana jika poin hasil prediksi saya dan lawan duel saya imbang atau sama besar?",
                "a": "Jika jumlah poin prediksi antara Anda dan lawan duel Anda sama besar, maka duel akan berakhir dengan hasil imbang dan tidak akan ada poin yang terpotong. Anda masih memiliki kesempatan untuk melakukan duel kembali di minggu berikutnya."
            },
            {
                "q": "Apa yang terjadi jika saya menang atau kalah duel?",
                "a": "Pemenang Duel akan mengambil semua Poin Mola yang dimiliki oleh lawan Duelnya, sementara peserta yang kalah dalam Duel akan kehilangan seluruh Poin Mola yang mereka peroleh pada minggu tersebut. Perubahan jumlah Mola Poin yang diperoleh akibat Duel akan mempengaruhi posisi peserta Duel dalam papan peringkat mingguan dan bulanan."
            },
            {
                "q": "Bagaimana cara menantang duel teman saya?",
                "a": "<div>Cara untuk menantang duel:<br><ol><li>Pastikan Anda dan teman Anda adalah pelanggan berbayar Mola.<li>Masuk ke Fitur “Duel dengan Temanmu”, lalu undang teman Anda dengan memasukan ‘Nama Tampilan’ yang ingin Anda ajak duel. Pastikan Anda dan lawan duel Anda sudah melakukan prediksi semua pertandingan terlebih dahulu.<li>Kemudian klik tombol kirim undangan duel<li>Lawan duel Anda akan menerima notifikasi ajakan duel Anda<li>Setelah lawan duel Anda menerima ajakan duel, maka Anda akan menerima notifikasi pada kotak masuk akun Anda.</ol></div>"
            },
            {
                "q": "Apa yang dimaksud dengan fitur ‘Duel dengan Pemain Acak?",
                "a": "Fitur ‘Duel dengan Pemain acak’ adalah fitur di mana Anda dapat menantang pemain secara Acak yang terdaftar di Mola Fight Club untuk mempertaruhkan seluruh poin yang diperoleh dari hasil prediksi yang dilakukan pada minggu tersebut. Pemenang duel akan ditentukan berdasarkan jumlah poin hasil prediksi tertinggi dan akan merebut semua poin dari lawan duelnya. Anda hanya dapat mengajak duel 1 (satu) lawan duel pada setiap minggunya."
            },
            {
                "q": "Bagaimana cara menantang Duel dengan Pemain Acak?",
                "a": "<div>Cara untuk menantang duel:<br><ol><li>Pastikan Anda dan teman Anda adalah pelanggan berbayar Mola.<li>Masuk ke Fitur “Duel dengan Temanmu”, pastikan Anda sudah melakukan prediksi semua pertandingan terlebih dahulu.<li>Klik tombol pilih acak lawan<li>Kemudian klik tombol kirim undangan duel<li>Setelah lawan duel Anda menerima ajakan duel, maka Anda akan menerima notifikasi pada kotak masuk akun Anda.</ol></div>"
            },
            {
                "q": "Apakah saya bisa mengubah lawan dalam duel?",
                "a": "Perubahan lawan dalam duel hanya dimungkinkan jika ajakan sudah ditolak oleh lawan duel Anda."
            },
            {
                "q": "Apakah saya dapat membatalkan ajakan duel yang sudah diterima oleh lawan duel saya?",
                "a": "Untuk ajakan duel yang sudah diterima oleh lawan duel Anda tidak dapat dibatalkan."
            },
            {
                "q": "Bagaimana cara melihat riwayat Poin yang didapatkan atau hilang setelah duel?",
                "a": "Anda dapat melihat riwayat Poin hasil dari duel yang Anda lakukan dengan membuka menu ‘Profil & Poin’, lalu pilih ‘Poin Mingguan’, riwayat Poin akan tampil pada bagian bawah halaman."
            },
            {
                "q": "Apa itu fitur “Bagikan Prediksi Anda\"?",
                "a": "Fitur ini berfungsi untuk membagikan prediksi yang sudah Anda lakukan, ke media sosial ataupun guna mengunduh prediksi Anda."
            },
            {
                "q": "Bagaimana cara membagikan prediksi saya?",
                "a": "Masukan seluruh prediksi Anda terlebih dahulu lalu, kemudian ke tombol ‘Bagikan Prediksi Anda’ lalu salin atau unduh prediksi Anda dan bagikan di media sosial."
            }
        ]
    },
    {
        "title": " Tentang Langganan Mola",
        "questions": [
            {
                "q": "Kategori pengguna mana yang dapat mengakses SuperChallenge Fight Predictor?",
                "a": "SuperChallenge Fight Predictor dapat diakses oleh tiga kategori pelanggan yang berbeda: pelanggan berbayar Mola, pelanggan gratis Mola, dan individu yang saat ini bukan pelanggan Mola."
            },
            {
                "q": "Langkah-langkah apa yang harus saya ikuti untuk menjadi pelanggan gratis Mola?",
                "a": "<div>Untuk mendaftar menjadi pelanggan gratis Mola, ikuti langkah-langkah berikut:<ol><li>Buka https://mola.tv/ di peramban web Anda.</li><li>Klik pada opsi untuk mendaftar akun baru dan berikan detail yang diperlukan.</li><li>Setelah berhasil mendaftar, masuk ke akun Anda untuk mendapatkan akses.</li></ol></div>"
            },
            {
                "q": "Bagaimana cara saya menjadi pelanggan berbayar Mola?",
                "a": "<div>Untuk menjadi pelanggan berbayar Mola, ikuti langkah-langkah sederhana berikut:<ol><li>Buka peramban web Anda dan arahkan ke https://mola.tv/ atau buka aplikasi Mola pada smartphone Anda</li><li>Daftar akun baru dengan menyediakan detail yang diperlukan.</li><li>Kunjungi halaman profil Anda.</li><li>Akses bagian 'Paket Langganan'.</li><li>Pilih dan beli paket langganan yang sesuai dengan preferensi Anda.</li></ol></div>"
            },
            {
                "q": "Dapatkah saya berpartisipasi dalam SuperChallenge Fight Predictor tanpa berlangganan Mola?",
                "a": "Tentu saja! Meskipun Anda bukan pelanggan Mola, Anda masih dapat berpartisipasi dalam SuperChallenge Fight Predictor."
            },
            {
                "q": "Bagaimana cara mendaftar untuk bermain SuperChallenge Fight Predictor?",
                "a": "Untuk mendaftar SuperChallenge Fight Predictor, buka https://molafightclub.com/. Jika Anda adalah pelanggan Mola, gunakan alamat email yang sama yang terhubung dengan akun Mola Anda. Jika Anda bukan pelanggan Mola, cukup mendaftar menggunakan alamat email pilihan Anda. Anda juga perlu membuat kata sandi untuk akun molafightclub.com Anda."
            },
            {
                "q": "Apa itu ‘Kode Undang’?",
                "a": "‘Kode Undang’ adalah kode unik yang dapat Anda bagikan kepada teman-teman Anda untuk mendaftar di Mola Fight Club. ‘Kode Undang’ terdiri dari kombinasi unik antara huruf dan angka."
            },
            {
                "q": "Bagaimana cara melihat dan membagikan ‘Kode Undang’?",
                "a": "‘Kode Undang’ dapat dilihat melalui menu ‘Profil & Poin’, lalu pilih ‘Profil Saya’ kemudian klik tombol ‘Undang Sekarang’, kemudian klik ‘Menyalin ke Clipboard’. ‘Kode Undang’ tersebut dapat Anda bagikan ke teman yang belum terdaftar di Mola Fight Club."
            },
            {
                "q": "Bagaimana cara penggunaan ‘Kode Undang’?",
                "a": "Kode Undang dapat dimasukan pada saat pengguna baru melakukan pendaftaran akun Mola Fight Club."
            },
            {
                "q": "Apa keuntungan membagikan dan menggunakan fitur ‘Undang Teman Anda’?",
                "a": "<ol><li>Dengan membagikan ‘Kode Undang’, Anda sebagai pengguna yang membagikan Kode Undang akan mendapatkan 5 Poin Mola setelah teman Anda melakukan pendaftaran dan prediksi menggunakan Kode Undang Anda.</li><li>Dengan menggunakan ‘Kode Undang’ milik teman Anda saat mendaftar, Anda akan mendapatkan 5 Poin Mola setelah melakukan prediksi pertama kali.</li></ol>"
            },
            {
                "q": "Bagaimana cara melihat Riwayat ‘Penghasilan Referral Saya’?",
                "a": "Untuk melihat pengguna yang menggunakan ‘Kode Undang’ Anda dan riwayat penghasilan dari referral dapat dilihat melalui menu ‘Profil & Poin’, lalu pilih ‘Profil Saya’, kemudian klik “Penghasilan Referral Saya”."
            }
        ]
    },
    {
        "title": " Katalog Mola dan Penukaran Hadiah",
        "questions": [
            {
                "q": "Poin Mola dapat saya gunakan untuk apa?",
                "a": "Sebagai pelanggan berbayar Mola, Poin Mola yang Anda kumpulkan dapat ditukarkan dengan hadiah eksklusif."
            },
            {
                "q": "Apa itu Katalog Mola?",
                "a": "Katalog Mola adalah halaman dengan semua hadiah yang dapat ditukar, dimana pengguna dapat menggunakan Poin Mola yang mereka peroleh dari memprediksi pertarungan MMA di SuperChallenge Fight Predictor."
            },
            {
                "q": "Bagaimana cara mengakses Katalog Mola?",
                "a": "Anda dapat mengakses Katalog Mola dengan masuk ke akun SuperChallenge Fight Predictor Anda dan mengklik menu ‘Tukar Poin’."
            },
            {
                "q": "Bagaimana cara mendapatkan hadiah dari Katalog Mola?",
                "a": "Anda dapat mendapatkan hadiah dengan mengklik tombol 'Menukarkan’, baik di halaman detail produk maupun di kartu produk di halaman utama katalog. Jika produknya adalah barang fisik, Anda perlu mengisi formulir detail pengiriman."
            },
            {
                "q": "Bagaimana cara melacak status barang saya?",
                "a": "Anda dapat melacak status barang Anda di halaman 'Status Pesanan'. Status yang mungkin ada: Diminta, Diproses, Sedang Dikirim, Selesai, dan Ditolak."
            },
            {
                "q": "Apa arti dari setiap status pesanan?",
                "a": "Diminta: Status ini muncul ketika Anda meminta produk.<br>Diproses: Status ini muncul ketika permintaan Anda sedang diproses.<br>Sedang Dikirim: Status ini muncul jika produknya berupa barang fisik dan sedang dikirimkan kepada Anda.<br>Selesai: Status ini muncul ketika produk telah tiba atau telah diberikan kepada Anda."
            },
            {
                "q": "Apa itu halaman 'Riwayat Penukaran'?",
                "a": "Halaman 'Riwayat Penukaran' menampilkan catatan semua produk yang telah Anda tukarkan dari Katalog Mola menggunakan poin yang Anda peroleh di SuperChallenge Fight Predictor."
            }
        ]
    },
    {
        "title": " Kontak dan Dukungan",
        "questions": [
            {
                "q": "Kepada siapa saya dapat menghubungi jika saya memiliki pertanyaan tentang status pengiriman barang atau pertanyaan lain tentang situs Mola Fight Club?",
                "a": "Jika Anda memiliki pertanyaan tentang status pengiriman barang atau pertanyaan lain tentang Katalog Mola, Anda dapat menghubungi kami di info@molafightclub.com."
            }
        ]
    }
]
const ITA = {
  //COMMON
  HOME: 'Home',
  BROADCAST_SCHEDULE: 'Programma',
  WATCH_THE_MATCH: 'Guarda la partita',
  PREDICT_NOW: 'Prevedi ora',
  PREDICT_NOW_BTN: 'Prevedi ora',
  LEADERBOARD: 'Classifica',
  HOW_TO_PLAY: 'Come si gioca',
  NEWS: 'Notizie',
  CONTACT: 'Contatto',
  LOGIN: 'Accedi',
  CLOSE: 'Chiudi',
  FIGHT_WEEK: 'FIGHT WEEK ',
  LIVE_NOW: ' In onda',
  CONTINUE: 'Continua',
  USER_NAME: 'Nome Utente',
  HOURS: ' ore',
  MIN: ' minuti',
  DAYS: ' giorni',
  MONTHS: ' mesi',
  YEARS: ' anni',
  SIGN_OUT: 'DISCONNESSIONE',
  MY_ACCOUNT: 'Profilo e punti',
  USER_DOES_NOT_EXIST: "L'utente non esiste",
  TOTAL: 'Totale',
  //HOME
  HOME_TITLE: 'FAI IL TUO PRONOSTICO <br> E VINCI.',
  HOME_TAGLINE: `Indovina l'esito del match e partecipa per avere la possibilità <br> di vincere grandi premi ogni settimana.`,
  HOME_BUTTON: 'Prevedi ora',
  HOME_BUTTON_PREDICT: 'PREVEDI',
  HOME_BUTTON_NOW: 'ORA',
  HOME_READ_BEFORE_PREDICTING: 'Leggi prima di prevedere',
  HOME_HOW_TO_PLAY: 'Come giocare',
  HOW_TO_PLAY_NO_RECORD: "L'assegnazione dei punti dell'evento successivo verrà mostrata qui quando l'evento sarà visibile nel programma di trasmissione",
  HOME_READ_BEFORE_PREDICTING_TEXT: "Prima di registrare le tue previsioni, sii sicuro di conoscere il sistema dei punti di Mola Fight Predictor:<br><br>" +
  "<ol>" +
  "<li>Indovinare il risultato esatto dell’incontro ti darà 3 punti.</li><br>" + 
  "<li>Azzeccare anche il modo in cui è finito l’incontro - se per KO/TKO, sottomissione o decisione - ti garantirà un totale di 12 punti (4 punti moltiplicati per i due del risultato).</li><br>" + 
  "<li>Se indovini in quanti round finirà il match, farai un totale di 9 punti (3 punti moltiplicati per i 2 punti del risultato dell’incontro).</li><br>" + 
  "<li>Se indovini correttamente tutte e tre le categorie - il risultato, il modo e il numero di round - otterrai un extra bonus di 2 punti. Quindi se avrai indovinato tutto avrai ottenuto 24 punti più 2 e quindi un totale di 26 punti.</li><br>" + 
  "</ol>" +
  "Non dimenticarti che ogni evento c’è un incontro che vale quadruplicare punti. Se dovessi indovinare tutte le categorie di quel match, allora i 24 punti diventeranno 96 e a questi verranno poi aggiunti 2 punti addizionali per aver indovinato tutte le categorie e avrai ottenuto 98 punti.<br><br>" + 
  "Ricordati che se non indovini il risultato dell’incontro, l’intera previsione varrà 0 punti, anche se avrai indovinato se finisce per KO/TKO, decisione o sottomissione oppure se avrai indovinato il numero di round.<br><br>" +
  "ATTENZIONE: I programmi dei match su questo sito sono soggetti a modifiche in tempo reale in conformità con le tempistiche ufficiali stabilite dalle promotion MMA come Bellator, Cage Warriors o Bellator. Se un combattente cambio dopo che hai fatto una previsione, questa verrà automaticamente annullata. Dovrai dunque fare una nuova previsione per il combattimento. Gli utenti possono cambiare tutte le previsioni fino alla scadenza dell’invio di queste. È responsabilità di ogni utente monitorare gli aggiornamenti degli eventi per cambiare eventualmente le proprie previsioni.",
  REWARD: 'Premi',
  // TBD
  //BROADCAST
  BROADCAST_NOTES: 'Nota bene che la Card Preliminare è in una scheda separata',
  BROADCAST_NOTES_MORE_EVENTS: 'Per favore, seleziona prima un evento.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Early Prelims',
  BROADCAST_MAIN_EVENT: 'Main Event',
  PLAYER_FAV: 'Il preferito dai lettori MFP',
  BROADCAST_POINTS_INFO: 'Informazioni sui punti',
  BROADCAST_FIGHTER_RECORD: 'Record del fighter',
  BROADCAST_POINTS_ALLOCATION: 'Assegnazione di punti per questo evento',
  BROADCAST_POINTS_ALLOCATION_NO_EVENT: 'Assegnazione punti',
  BROADCAST_CORRECT_OUTCOME: 'Esito corretto (vittoria, pareggio): ',
  BROADCAST_CORRECT_METHOD: 'Metodo corretto (KO/TKO eccetera.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Metodo corretto sottomissione: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Metodo corretto decisione: ',
  BROADCAST_BONUS: 'Bonus sul risultato corretto',
  BROADCAST_BONUS_POINTS: 'Punti',
  BROADCAST_BONUS_ALL: 'Bonus sul corretto Risultato, Metodo & Round:',
  BROADCAST_CORRECT_ROUND: 'Numero corretto di round: ',
  BROADCAST_MULTIPLIER_MAIN: 'Moltiplicatore di punti Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Moltiplicatore di punti Card Preliminare: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Moltiplicatore di punti in evidenza: ',
  BROADCAST_404: 'Da pronosticare',
  BROADCAST_COUNTDOWN: 'Potrai fare i pronostici a partire dal ',
  BROADCAST_CLOSE_ON: 'Previsione chiusa il ',
  BROADCAST_AVAILABLE_SOON: `L'evento è terminato: i risultati della classifica saranno disponibili a breve.`,
  BROADCAST_END: `L'evento è terminato: i risultati vincenti possono essere visti nella classifica.`,
  BROADCAST_HOW: 'Come?',
  BROADCAST_WHICH_ROUND: 'Quale Round?',
  BROADCAST_HOW_2: 'Come:',
  BROADCAST_WHICH_ROUND_2: 'Round:',
  BROADCAST_CANCELLED: 'Questa lotta è stata annullata!',
  BROADCAST_LOGIN: 'Accedi per fare una previsione',
  BROADCAST_EVENT_404: 'Al momento non ci sono eventi imminenti.',
  BROADCAST_STAY_TUNED: 'Rimani sintonizzato!',
  FIGHTER_PROFILE: 'Profilo del Fighter',
  FIGHTER_SEE_MORE: 'Vedi qui altri dettagli su questo fighter',
  SPONSORED: 'Incontro speciale',
  MFP_FAVOURITE: 'I PREFERITI DEI LETTORI MFP',
  PREDICTIONS_FROM: 'Pronostici di {x} utenti',
  PREDICTIONS_FROM_ONE: 'Pronostici di 1 utenti',
  //RECAP
  RECAP_MON_RANK: 'Classifica Mensile',
  RECAP_MON_POINT: 'Punti Mensili',
  RECAP_WEEK_RANK: 'Classifica Settimanale',
  RECAP_WEEK_POINT: 'Classifica Settimanale',
  //AUTH
  AUTH_LOGIN: 'Accedi a Mola - Fight Predictor',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Password',
  AUTH_PASSWORD_HINT: 'Minimo 8 caratteri',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Hai già un account? Accedi con la tua password.',
  AUTH_BUTTON: 'Login / Iscriviti',
  AUTH_CONTINUE_WITH_GOOGLE: 'Continua con Google',
  AUTH_CONFIRM_EMAIL: 'Controlla la tua email!',
  AUTH_CONFIRM_EMAIL_2:
    'Il link di conferma è stato già inviato alla tua email!',
  AUTH_EMAIL_VALIDATION: `L'e-mail deve essere valida`,
  AUTH_ONE_STEP_AHEAD: 'Un passo avanti',
  AUTH_INVALID_EMAIL: 'Link email non valido!',
  AUTH_WELCOME_BACK:
    '"Welcome Back! Ti reindirizzeremo alla pagina iniziale in pochi secondi..."',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'Classifica mensile',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'Classifica settimanale',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'Classifica settimanale FUN',
  LEADERBOARD_MONTHLY: 'Mensile',
  LEADERBOARD_WEEKLY: 'Settimanale',
  LEADERBOARD_MONTHLY_FUN: 'Mensile Fun',
  LEADERBOARD_WEEKLY_FUN: 'Settimanale Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Mensile Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Settimanale Reward',
  LEADERBOARD_RANK: 'Classifica',
  LEADERBOARD_TOTAL_POINTS: 'Punti totali',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Punti Mola Totali',
  LEADERBOARD_POINTS: 'Punti',
  LEADERBOARD_CORRECT_OUTCOME: 'Esito corretto',
  LEADERBOARD_CORRECT_METHOD: 'Metodo corretto',
  LEADERBOARD_CORRECT_ROUNDS: 'Round corretti',
  LIVE_EVENT_LEADERBOARD: 'Classifica Evento Live',
  LEADERBOARD_DETAILS_FOR: 'Dettagli per',
  LEADERBOARD_MY_RANKING: 'La mia posizione',
  LEADERBOARD_NO_RANKING: 'Nessuna posizione',
  //PREDICTION
  PREDICTION: ' PRONOSTICO',
  PREDICTION_GAME: 'Gioco',
  PREDICTION_OUTCOME: 'Esito',
  PREDICTION_METHOD: 'Metodo',
  PREDICTION_ROUNDS: 'Turno',
  PREDICTION_SUBMISSION: 'Sottomissione',
  PREDICTION_DECISION: 'Decisione',
  PREDICTION_LOSES: 'Sconfitta',
  PREDICTION_WIN: 'Vittoria',
  PREDICTION_WINNER: 'Vincitore',
  PREDICTION_DRAW: 'Pareggio',
  COPY_PREDICTION: 'Copia o scarica la tua previsione',
  SHARE_PREDICTION: 'Condividi sui social media',
  YOUR_PREDICTION: 'La Tua Previsione',
  YOUR_PREDICTION_1: 'La Tua',
  YOUR_PREDICTION_2: 'Previsione',
  WHICH_ROUND: 'Quale round?',
  SUBMIT_PREDICTION: 'Fai la tua previsione',
  UPDATE_PREDIDCTION: 'Aggiornare la previsione',
  CHANGE_PREDIDCTION: 'Cambiare la previsione',
  INFO_PREDICTION: 'La tua previsione è stata inviata.',
  PREDICTION_CLOSE_IN: 'Ricordati di fare la tua previsione entro il ',
  IN_X_DAYS: '{x} giorni prima',
  //CONTACT
  CONTACT_US: 'CONTATTACI',
  CONTACT_MESSAGE:
    'Non esitate a contattarci se avete bisogno di ulteriori informazioni o in caso di problemi.',
  CONTACT_BUTTON: 'INVIA UNA MAIL',
  //NEWS
  NEWS_MFP: 'NOTIZIE DEL SUPERCHALLENGE',
  NEWS_VIEWS: ' Visualizzazioni',
  NEWS_VIEW: ' Visualizzazioni',
  NEWS_SHARE: 'Condividi',
  NEWS_DAYS_AGO: ' giorni fa',
  NEWS_SHARE_VIA: 'Condividere tramite',
  // FAQ
  FAQ: 'FAQ',
  FAQ_LONG: 'FREQUENTLY ASKED QUESTIONS',
  FAQ_TITLE_01: '1. Riguardo il SuperChallenge Fight Predictor',
  FAQ_TITLE_02: '2. Riguardo gli abbonamenti Mola',
  FAQ_TITLE_03: '3. Catalogo Mola e Riscatto dei Premi?',
  FAQ_TITLE_04: '4. Contatti e Supporto',
  //MY ACCOUNT HOME
  MY_ACCOUNT_WELCOME: 'Benvenuto',
  MY_ACCOUNT_MOLA_POINTS: 'Punti',
  MY_ACCOUNT_POINTS_WON: 'Punti vinti',
  MY_ACCOUNT_SWAP: 'Riscatta il tuo premio',
  MY_ACCOUNT_PROFILE: 'Il mio profilo',
  //PROFILE
  MY_PROFILE: 'Il mio profilo',
  MY_PROFILE_ACCOUNT_VERIFICATION: `Verifica dell'account`,
  MY_PROFILE_ACCOUNT_EMAIL: 'Email Account',
  MY_PROFILE_PASSWORD: 'Password',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Informazioni account',
  MY_PROFILE_ACCOUNT_TYPE: 'Tipo di account',
  MY_PROFILE_ACCOUNT_STATUS: `Stato dell'account`,
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Iscrizione a Mola',
  MY_PROFILE_ACTIVE: 'Attivo',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Non abbonato Mola',
  MY_PROFILE_FREE: 'Gratuito',
  MY_PROFILE_PERSONAL_INFORMATION: 'Informazioni personali',
  MY_PROFILE_DISPLAY_NAME: 'Nome utente',
  MY_PROFILE_ABOUT_ME: 'Informazioni su di me',
  MY_PROFILE_FULL_LEGAL_NAME: 'Nome completo',
  MY_PROFILE_DOB: 'Data di nascita',
  MY_PROFILE_GENDER: 'Genere',
  MY_PROFILE_ADDRESS_DETAILS: 'Dettagli indirizzo',
  MY_PROFILE_LANGUAGE: 'Lingua',
  MY_PROFILE_CURRENT_VERSION: 'Versione attuale',
  MY_PROFILE_TANDC: 'Termini e condizioni',
  MY_PROFILE_PRIVACY_POLICY: 'Informativa sulla privacy',
  MY_PROFILE_UPGRADE_NOW: 'Aggiorna ora',
  MY_PROFILE_SUBSCRIBE_NOW: 'Iscriviti ora',
  MY_PROFILE_SIGN_OUT: 'Esci dal mio account',
  MY_PROFILE_BUTTON_CHANGE: 'Modifica',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Inserisci la tua data di nascita',
  MY_PROFILE_GENDER_NOT_SET: 'NON IMPOSTATO',
  MY_PROFILE_GENDER_M: "MASCHIO",
  MY_PROFILE_GENDER_F: "FEMMINILE",
  MY_PROFILE_GENDER_O: 'OTHERS',
  MY_PROFILE_PASSWORD_SET: 'Per favore, imposta la tua password',
  MY_PROFILE_NEW_EMAIL: 'Per favore, imposta la tua nuova email',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Modifica Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  MY_PROFILE_PASSWORD_SUGGESTION: 'Suggerimenti per password efficaci',
   MY_PROFILE_PASSWORD_SUGGESTION_TEXT: `
     &bull; Almeno 8 caratteri: più sono, meglio è.<br />
     &bull; Una combinazione di lettere maiuscole e minuscole.<br />
     &bull; Un misto di lettere e numeri.<br />
     &bull; Inclusione di almeno un carattere speciale, ad esempio ! @#? ]
  `,
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Nome utente aggiornato',
  SETTINGS_UPDATED_ABOUTME: 'Informazioni su di me aggiornate',
  SETTINGS_UPDATED_FULLNAME: 'Nome completo aggiornato',
  SETTINGS_UPDATED_DOB: 'Data di nascita aggiornata',
  SETTINGS_UPDATED_GENDER: 'Genere aggiornato',
  SETTINGS_UPDATED_ADDRESS: 'Indirizzo aggiornato',
  SETTINGS_UPDATED_LANGUAGE: 'Lingua aggiornata',
  SETTINGS_UPDATED_PHOTO: 'Foto del profilo aggiornata',
  //POINTS
  POINTS_NON_MOLA:
    'Poiché sei un utente registrato ma non abbonato, i punti che vedi sono un simulazione che possono essere vinti solo dopo aver sottoscritto un abbonamento a Mola.',
  POINTS_FOR: 'Punti vinti per eventi',
  POINTS_404:
    'Nessun risultato o punto vinto per i combattimenti di questo evento',
  POINTS_MORE: 'Carica altro dagli eventi passati',
  POINTS_HISTORY: 'Cronologia punti vinti',
  POINTS_HISTORY_404: 'Nessuna cronologia dei punti vinti trovata',
  //ADMIN
  ADMIN_DASHBOARD: `Pannello dell'Admin`,
  GAME_MANAGEMENT: 'Gestione del gioco',
  ADMIN_MANAGEMENT: 'Admin Tasks',
  EVENTS_AND_GAMES: 'Eventi e giochi',
  GAME_RESULTS: 'Risultati del gioco',
  CREATE_EVENT: 'Crea evento',
  NEWS_MANAGEMENT: 'Gestione delle notizie',
  HOME_MANAGEMENT: 'Home Management',
  WATCH_MANAGEMENT: 'Guarda Gestione',
  REWARD_MANAGEMENT: 'Gestione delle ricompense',
  WATCH_MATCH_FREE: 'Guarda la partita gratuitamente',
  WATCH_ON_MOLA: 'Guarda su Mola',
  POINTS_AND_TOKEN_MANAGEMENT: 'Gestione punti e token',
  POINTS_MANAGEMENT: 'Gestione dei punti',
  SWAP_REQUEST: 'Richiesta di scambio',
  MANAGE_EVENTS_AND_GAMES: 'Gestisci eventi e giochi',
  PROCESS_GAME_RESULTS: 'Elabora i risultati del gioco',
  CREATE_NEW_PREDICTION_GAME: 'Crea un nuovo pronostico per eventi e match',
  MANAGE_NEWS: 'Gestisci le notizie',
  //DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: 'Totale Iscrizioni',
  ADMIN_TOTAL_SIGN_UPS_TODAY: 'Totale Iscrizioni di oggi',
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: 'Totale Iscrizioni di questo mese',
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: 'Totale Abbonati Mola',
  ADMIN_TOTAL_PREDICTIONS: 'Totale dei pronostici',
  ADMIN_TOTAL_POINTS_WON: 'Totale Punti Vinti',
  DATE: 'Data',
  EVENT: 'Eventi',
  FIGHT: 'Incontro',
  ROWS_PER_PAGE: 'Righe per pagina', 
  ROWS_PER_PAGE_OF: 'di',
  ROWS_PER_PAGE_ALL: 'Tutto',
  //FOOTER
  FOOTER_TITLE: 'FAI IL TUO PRONOSTICO E VINCI.',
  FOOTER_TITLE_NEW: 'ABBONATI A MOLA',
  MOLA_FIGHT_CLUB: 'SuperChallenge Fight Predictor',
  ABOUT: 'Informazioni su Mola Digital',
  SOCIAL: 'Social Networks',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Benvenuti a Mola - Fight Predictor',
  REGISTER_LOGGED: 'You are logged in!',
  REGISTER_CONTINUE: 'Continua per completare la creazione del tuo account',
  REGISTER_COMPLETE: 'Completa il tuo Account',
  REGISTER_IN_ORDER:
    'Per completare la configurazione del tuo account, abbiamo bisogno di più informazioni da te.',
  REGISTER_FULL_NAME: 'Nome completo',
  REGISTER_PASSWORD: 'Password',
  REGISTER_SUBMIT: 'Invia',
  REGISTER_WELCOME: 'Benvenuto',
  REGISTER_WELCOME_BACK: 'Welcome Back',
  REGISTER_THANKS:
    'Grazie per esserti unito a noi. Sarai reindirizzato alla pagina iniziale....',
  REGISTER_REDIRECT: 'Reindirizzamento alla home page in pochi secondi...',
  //Authorise
  LOADING: 'Caricamento...',
  ERROR: 'Errore...',
  PLEASE_WAIT: 'Attendere tra qualche secondo...',
  BACK_TO_HOME: 'TORNARE ALLA HOME', //'Tornare a casa',
  LOCAL_TIME: 'Orario locale',
  LOCAL: 'locale',
  LEADERBOARD_TITLE: 'CLASSIFICA MENSILE',
  AUTH_MESSAGE: `Il codice di attivazione non è corretto.
  Questo è successo poiché il codice non è
  stato scritto correttamente, è scaduto o è
  stato già usato.`,
  //New
  MOLA_CATALOG: 'Riscattare i punti',
  TO_MOLA_CATALOG_ADMIN: 'Al Catalogo Mola',
  ALL_FIGHTS: 'Tutti i combattimenti',
  MEMBER_SINCE: 'Membro dal',
  PROFILE_SCORE: 'Punteggio profilo',
  VERIFIED: 'Verificato',
  NOT_VERIFIED: 'Non verificato',
  VERIFY: 'Verifica',
  IMA: 'Sono un ',
  STARTER: 'Antipasto',
  ACTIVE: 'Attivo',
  INACTIVE: 'Inattivo',
  SUSPENDED: 'Sospeso',
  HOW_TO_PLAY_TEXT: "<ol>" +
  "<li>Clicca sul pulsante 'Login' e quindi su 'Registrati' per registrarti.</li><br>" + 
  "<li>Se possiedi un account attivo su Mola, assicurati di utilizzare lo stesso indirizzo mail che hai inserito per registrarti a Mola. Se non sei registrato a Mola, puoi semplicemente utilizzare il tuo indirizzo mail.</li><br>" + 
  "<li>Completa il tuo profilo inserendo i dati personali nella pagina 'Il mio profilo'.</li><br>" + 
  "<li>nserisci i tuoi pronostici per l'evento che sta per iniziare. Assicurati di pronosticare tutti i combattimenti disponibili per ottenere il massimo dei punti.</li><br>" + 
  "<li>Se vuoi modificare i tuoi pronostici, puoi farlo direttamente nella pagina 'Pronostici' cliccando sul pulsante 'Cambia Pronostico'.</li><br>" + 
  "<li>Guarda l'evento su Mola e scopri i risultati.</li><br>" + 
  "<li>Quando gli incontri finiscono, puoi consultare i tuoi 'Weekly Points' sulla tua pagina-profilo e controllare la tua posizione in classifica nella pagina 'Leaderboard'.</li><br>" + 
  "<li>SuperChallenge Fight Predictor è accessibile a chiunque, ma assicurati di avere un profilo registrato su Mola se vuoi guadagnare 'Mola Punti', che puoi successivamente riscattare per ottenere ricompense esclusive disponibili sul catalogo di Mola.</li><br>" +
  "</ol>",
  REDEEM_NOW: 'Riscatta ora!',
  LOAD_MORE: 'Carica altro',
  PREDICTION_ALERT: 'Assicurati di compilare tutti i campi <strong>previsione</strong> qui sopra.',
  SECONDS_AGO: ' secondi fa',
  MINUTES_AGO: ' minuti fa',
  HOURS_AGO: ' ore fa',
  DAYS_AGO: " giorni fa",
  MONTHS_AGO: ' mesi fa',
  YEARS_AGO: ' anni fa',
  COMPLEANNO: 'Compleanno',
  CITTÀ: 'Città',
  POSTAL_CODE: 'Codice postale',
  // Mola Punti and Weekly Points Won - Quick Guide
  QUICK_GUIDE_TITLE: 'Mola Punti e Punti vinti settimanalmente - Guida rapida',
  QUICK_GUIDE_SECTION_01: 'Punti settimanali vinti',
  QUICK_GUIDE_SECTION_01_TEXT: 'Guadagna punti settimanali per ogni pronostico corretto sul nostro sito. Questi punti sono in gran parte solo per divertimento, anche se possono darti un vantaggio competitivo nei nostri eventi speciali in futuro. Tutti gli utenti sono qualificati per guadagnare punti settimanali.',
  QUICK_GUIDE_SECTION_02: 'Mola Punti',
  QUICK_GUIDE_SECTION_02_TEXT: 'Esclusivo per gli utenti con abbonamento attivo a Mola. Oltre ai Punti settimanali vinti, lo stesso importo verrà automaticamente convertito in Punti Mola e aggiunto al vostro saldo di Mola Punti. Guadagna questi punti, oltre ai punti vinti settimanalmente, vincendo i tuoi pronostici. Assicurati che l’indirizzo e-mail utilizzato per i pronostici sia lo stesso dell’abbonamento a Mola. Ricorda che l’abbonamento a Mola deve essere attivo prima di inviare i pronostici.',
  QUICK_GUIDE_SECTION_03: 'Riscattare i punti',
  QUICK_GUIDE_SECTION_03_TEXT: 'Riscatta i Mola Punti per ottenere i premi del nostro Catalogo Mola. Vai al catalogo Mola o clicca su “Riscatta ora” nel menu “Mola Punti” per scambiare i punti. <strong>Solo i Mola Punti possono essere utilizzati per il riscatto.</strong>',
  DATA_404: 'Nessuna dato disponibile',
  LIVE_LEADERBOARD: "Classifica Evento Live",
  NEXT_FIGHT_UP: "Prossimo combattimento:",
  EVENT_END: `l'evento è terminato`,
  NO_RESULT: 'Non ci sono ancora i risultati. Il primo incontro non è ancora incominciato oppure è in corso.',
  NICKNAME: 'Il tuo nickname: ',
  AGE: 'Età: ',
  DOB: 'Data di nascita: ',
  GENDER: 'Genere: ',
  WINS: 'Vittorie',
  LOSSES: 'Sconfitte',
  DRAWS: 'Pareggio',
  MINUTES: ' minuti',
  SECONDS: ' secondi',
  IN_HOURS_AND_MIN: '{x} ore e {y} minuti',
  SORT_BY_DATE: "Ordina per data",
  
  EMPTY: `{variable-name} non può essere vuoto`,
  MINIMUM_LENGTH: `la lunghezza minima di {variable-name} è {number}`,

  CHANGE_YOUR_PASSWORD: 'Cambia la tua password',
  NEW_PASSWORD: 'Nuova password',
  CURRENT_PASSWORD: 'Password attuale',
  SET_NEW_PASSWORD: 'Imposta nuova password',
  CLICK_TO_SELECT_A_PHOTO: 'Clicca per selezionare una foto',
  CHANGE_PHOTO: 'Cambia foto',
  REMOVE_PHOTO: 'Rimuovi foto',
  SAVE: 'Salva',
  SET_PICTURE: 'Imposta Immagine',
  TAP_TO_SELECT_PHOTO: 'Tocca qui per selezionare una foto <br>dalla tua galleria',
  AGREE: 'Accedendo, hai letto e accettato il',
  AND: 'e',
  DOWNLOAD_PLAYSTORE: 'Scarica Mola sul Playstore',
  DOWNLOAD_APPSTORE: 'Scarica Mola sul Appstore',
  INSTAGRAM: 'Instagram @mola.sport',
  TIKTOK: 'Tiktok @mola.sport',

  // Duelling
  DUEL_PROVE: `
     Dimostra che sei migliore di loro!
     <br/>
     Se ottieni punti più alti nei tuoi pronostici rispetto al tuo avversario,
     Otterrai tutti i loro punti mola dalla previsione di questa settimana
   `,
   DUEL_TITLE: 'DUELLO CON UN AMICO',
   INVITATION_SENT: 'INVITO INVIATO',
   HOW_IT_WORKS: 'Come funziona?',
   WHOS_YOUR_OPPONENT: `CHI È IL TUO AVVERSARIO?`,
   SEARCH_OPPONENT: 'Cerca il nome utente del tuo avversario',
   SEARCH_OPPONENT_2: 'Cerca il nome utente del tuo avversario',
   SEND_DUEL_INVITATION: 'INVIA INVITO A DUELLO',
   DUEL_CONFIRM: 'Per favore controlla la tua notifica più tardi!',
   DUEL_CAPTION: 'Il tuo invito al duello è già stato inviato al tuo avversario! Una volta che il tuo avversario accetta o rifiuta, ti invierà una notifica!',
   DUEL_USER_ALREADY_INVITED: ' è già stato invitato a un altro duello',
   FROM: 'Da',
   TO: 'A',
   DUEL_BUTTON: 'Duello con il tuo amico',
   SHARE_BUTTON: 'Condividi la tua previsione',
   DUEL_SHARE_INFO: 'Assicurati di aver inviato le previsioni per le 5 partite sopra per la funzione "Condividi la tua Previsione" e "Duello con il tuo Amico".',
   DUEL_WIN: 'Vinci nel duello pronostico ',
   DUEL_LOSE: 'Perdi nel duello pronostico ',
   DUEL_DRAW: 'Pareggio nel Duello Pronostico ',
   DUEL_WIN_WITH: 'Vinci nel duello pronostico con ',
   DUEL_LOSE_WITH: 'Perdi nel duello pronostico con ',
   DUEL_DRAW_WITH: 'Pareggio nel Duello Pronostico con ',
   OPPONENT_NOT_MOLA_SUBSCRIPTION: `L'avversario non è un abbonato Mola`,
   DUEL_USER_MUST_BE_MOLA_SUBSCRIPTION: `Non sei un abbonato Mola`,
   ACCEPT: 'ACCETTARE',
   REJECT: 'RIFIUTARE',
   ACCEPT_DUEL: `L'invito è stato accettato`,
   REJECT_DUEL: `L'invito è stato declinato`,
   ACCEPT_DUEL_SENDER: 'L\'invito è stato accettato',
   REJECT_DUEL_SENDER: 'L\'invito è stato declinato',
  // Refer your friends
  MY_REFERRAL_EARNINGS: 'I MIEI GUADAGNI DA INVITA',
  REFERAL_CODE: 'Codice di invito (facoltativo)',
  INVALID_CODE: 'Codice di invito non valido!',
  CHECK_CODE: 'Per favore, controlla il tuo codice o continua senza.',
  CONGRATULATIONS: 'Congratulazioni!',
  FIRST_PREDICTION_TEXT1: 'Hai fatto la tua prima previsione.',
  FIRST_PREDICTION_TEXT2: 'Tu e il tuo amico, che ti ha invitato su MFP, avete appena ricevuto 5 Mola Points ciascuno.',
  CONTINUE_PREDICTING: 'Continua a prevedere',
  COPY_TO_CLIPBOARD: 'Copia negli appunti',
  REFER_YOUR_FRIENDS: 'INVITA I TUOI AMICI',
  REFER_YOUR_FRIENDS_1: 'INVITA',
  REFER_YOUR_FRIENDS_2: 'I TUOI AMICI',
  REFER_NOW: 'Invita ora',
  INVITE_TEXT1: 'Invita i tuoi amici su MFP!',
  INVITE_TEXT2: 'Tu e il tuo amico riceverete <span style="color:#f8df8e">5 PUNTI</span>',
  SHARE_CODE: 'Condividi il tuo codice referral <strong>"{x}"</strong> per regalare ai tuoi amici 5 Mola Points. Quando inviano la loro prima previsione, ricevi anche 5 Mola Points!',
  COPY_CODE: 'EHI! Competiamo su Mola Fight Predictor! Tu ed io riceveremo 5 punti Mola quando inserisci il mio codice referral <strong style="color: #2196F3">"{x}"</strong> e invii la tua prima previsione. <a href="https://molafightclub.com">Iscriviti qui<a/>',
  COPY_CODE_PLAIN: 'EHI! Competiamo su Mola Fight Predictor! Tu ed io riceveremo 5 punti Mola quando inserisci il mio codice referral "{x}" e invii la tua prima previsione. Iscriviti qui https://molafightclub.com',
  YOU_ALREADY_DUEL: 'Sei già in duello con:',
  SUCCESS_COPY_IMAGE: 'Immagine copiata negli appunti con successo!',
  NOTIFICATIONS: 'NOTIFICHE',
  INBOX: 'Arrivo',
  OUTBOX: 'Uscito',
  SENT: 'Inviato',
  FALLBACK_SHARE: 'Fitur berbagi tidak didukung di browser ini, silakan gunakan opsi unduh',
};

export default ITA;
import { db } from '../main';
function rankLeaderboard(listOfData){
    try{
        listOfData.sort((a, b) =>
          b.total_points === a.total_points
            ? a.last_prediction - b.last_prediction
            : b.total_points - a.total_points
        );
        let rank = 1;
        for (let i = 0; i < listOfData.length; i++) {
            if (i >= 1) {
                if (listOfData[i].total_points !== listOfData[i - 1].total_points) {
                    rank++;
                }
            }
            listOfData[i].rank = rank;
            // console.log(rank, listOfData[i].rank, listOfData[i].username, listOfData[i].total_points);
        }
        return listOfData;
    }catch(err){
        console.log(err)
    }
}
function rankLeaderboardReward(listOfData){
  try{
      listOfData.sort((a, b) =>
        b.total_mola_points === a.total_mola_points
          ? a.last_prediction - b.last_prediction
          : b.total_mola_points - a.total_mola_points
      );
      let rank = 1;
      for (let i = 0; i < listOfData.length; i++) {
          if (i >= 1) {
              if (listOfData[i].total_mola_points !== listOfData[i - 1].total_mola_points) {
                  rank++;
              }
          }
          listOfData[i].rank = rank;
          // console.log(rank, listOfData[i].rank, listOfData[i].username, listOfData[i].total_points);
      }
      return listOfData;
  }catch(err){
      console.log(err)
  }
}

async function getTotalPointsUsed(uid) {
  // console.log('Used', uid);
  const snap = await db.collection('points').where('type', '==', 2)
    .where('status', '!=', 3).where('uid', '==', uid).get();
  // var idTotalsMap = new Map();
  let total = 0;
  if(!snap.empty) {
    snap.forEach((doc) => {
      const item = doc.data();
      total += item.points;
    })
  }
  return (-1 * total);
}

function isDataValidForReward(obj){
  // return (obj.mola_subscription >= 0) && 
  //   !obj.email.includes('@mola.tv') && 
  //   obj.total_mola_points >= 0;
  return (obj.mola_subscription === 1 && !obj.email.includes('@mola.tv') && obj.total_mola_points >= 0) || 
    (obj.mola_subscription >= 0 && !obj.email.includes('@mola.tv') && obj.total_mola_points > 0)
}

export default {
  state: {
    funAllTimeLeaderboard: [],
    funLeaderboardMonthly: [],
    funLeaderboardWeekly: [],
    rewardAllTimeLeaderboard: [],
    rewardLeaderboardMonthly: [],
    rewardLeaderboardWeekly: [],
    leaderboardEventIds: [],
    funMonthlyLeaderboardMessage: '',
    funWeeklyLeaderboardMessage: '',
    rewardMonthlyLeaderboardMessage: '',
    rewardWeeklyLeaderboardMessage: '',
    userMap: new Map(),
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getLeaderboardAllTime({ commit }, payload) {
      // const today = new Date().getTime();
      // const lastMonth = today - 1000 * 3600 * 24 * 60;
      let funLeaderboardList = [];
      let rewardLeaderboardList = [];
      let pointPromise = [];
      let query = db
        .collection('leaderboard')
        .orderBy('total_points', 'desc')
        .orderBy('email', 'desc')
        .limit(payload.limit);
      console.log(query);
      try{
        let snapshot = await query.get();
        console.log("Leaderboard", snapshot);
        if (snapshot.empty) {
            commit('setState', {
                rewardMonthlyLeaderboardMessage: 'Leaderboard Data not found',
                funMonthlyLeaderboardMessage: 'Leaderboard Data not found',
            });
            return;
        }
        if (payload.loadMore) {
            funLeaderboardList = payload.funLeaderboard;
            rewardLeaderboardList = payload.rewardLeaderboard;
        }
        snapshot.forEach((doc) => {
            const obj = doc.data();
            // console.log(obj);
            obj.doc_id = doc.id;
            var inFunLeaderboard =
            funLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
            var inRewardLeaderboard =
            rewardLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
            if (!inFunLeaderboard) {
              funLeaderboardList.push({ ...obj });
            }
            if (!inRewardLeaderboard && isDataValidForReward(obj)) { // && obj.modified > lastMonth
              // obj.total_points = obj.total_mola_points
              const pointUsedPromise = getTotalPointsUsed(obj.uid).then((pointUsed) => {
                console.log('Used', pointUsed);
                return pointUsed;
              })
              console.log(pointUsedPromise)
              pointPromise.push(pointUsedPromise);
              rewardLeaderboardList.push({ 
                ...obj,
              });
            }
        });
        // rewardLeaderboardList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          // return b.total_mola_points - a.total_mola_points;
        // });
        // rewardLeaderboardList.forEach(item => {
        //   if (molaPointUsedMap.has(item.uid)) {
        //     item.point_used = molaPointUsedMap.get(item.uid);
        //   }
        // })
        console.log('rewardLeaderboardList', rewardLeaderboardList.length)
        const pointList = await Promise.all(pointPromise) //Wait for all promies to be resolve to get the point
        pointList.forEach((item, index) => {
          rewardLeaderboardList[index].point_used = item;
        })
        commit('setState', {
          funAllTimeLeaderboard: rankLeaderboard(funLeaderboardList),
          rewardAllTimeLeaderboard: rankLeaderboardReward(rewardLeaderboardList),
        });
      } catch (err) {
        console.log(err)
        commit('setState', {
          rewardMonthlyLeaderboardMessage: err.message,
          funMonthlyLeaderboardMessage: err.message,
        });
      }
    },
    async getLeaderboardByMonth({commit}, payload){
      let funLeaderboardList = [];
      let rewardLeaderboardList = [];
      console.log(payload);
      let query = db
        .collection('monthly_leaderboard')
        .doc(payload.filter);
      try {
        let doc = await query.get();
        // console.log('Leaderboard', doc);
        // console.log('Leaderboard', doc.data());
        if (!doc.exists) {
          commit('setState', {
            rewardMonthlyLeaderboardMessage: 'Leaderboard Data not found',
            funMonthlyLeaderboardMessage: 'Leaderboard Data not found',
          });
          return;
        }
        const leaderboardArr = doc.data();
        const leaderboardId = Object.keys(leaderboardArr);
        // console.log(typeof leaderboardArr);
        // console.log(typeof leaderboardId);
        // console.log('Isi', leaderboardId);

        leaderboardId.forEach((key) => {
          const obj = leaderboardArr[key];
          // console.log(obj);
          obj.doc_id = doc.id;
          var inFunLeaderboard =
            funLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
          var inRewardLeaderboard =
            rewardLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
          if (!inFunLeaderboard) {
            funLeaderboardList.push({ ...obj });
          }
          if (!inRewardLeaderboard && isDataValidForReward(obj)) {
            // obj.total_points = obj.total_mola_points
            rewardLeaderboardList.push({ ...obj });
          }
        });

        if (payload.month === 4) { //  Temp solution !!!!!!!
          query = db
          .collection('monthly_leaderboard')
          .doc('monthly_leaderboard_test_4_2024');
          try {
          let doc = await query.get();
          // console.log('Leaderboard', doc);
          // console.log('Leaderboard', doc.data());
          if (!doc.exists) {
            commit('setState', {
              rewardMonthlyLeaderboardMessage: 'Leaderboard Data not found',
              funMonthlyLeaderboardMessage: 'Leaderboard Data not found',
            });
            return;
          }
          const leaderboardArr = doc.data();
          const leaderboardId = Object.keys(leaderboardArr);
          // console.log(typeof leaderboardArr);
          // console.log(typeof leaderboardId);
          // console.log('Isi', leaderboardId);

          leaderboardId.forEach((key) => {
            const obj = leaderboardArr[key];
            // console.log(obj);
            obj.doc_id = doc.id;
            var inFunLeaderboard =
              funLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
            var inRewardLeaderboard =
              rewardLeaderboardList.findIndex((item) => item.uid === obj.uid) > -1;
            if (!inFunLeaderboard) {
              funLeaderboardList.push({ ...obj });
            }
            if (!inRewardLeaderboard && isDataValidForReward(obj)) {
              // obj.total_points = obj.total_mola_points
              rewardLeaderboardList.push({ ...obj });
            }
          });

        } catch (err) {
          console.log(err);
          commit('setState', {
            rewardMonthlyLeaderboardMessage: err.message,
            funMonthlyLeaderboardMessage: err.message,
          });
        }
      } // end temp solution

      commit('setState', {
        funLeaderboardMonthly: rankLeaderboard(funLeaderboardList),
        rewardLeaderboardMonthly: rankLeaderboardReward(rewardLeaderboardList),
      });

      } catch (err) {
        console.log(err);
        commit('setState', {
          rewardMonthlyLeaderboardMessage: err.message,
          funMonthlyLeaderboardMessage: err.message,
        });
      }
    },
    async getLeaderboardWeekly({ commit }, payload) {
      let leaderboardEventIds = [];
      let funLeaderboardList = [];
      let rewardLeaderboardList = [];
      let leaderboardList = [];
      // const lastWeek = today - 1000 * 3600 * 24 * 7;
      console.log(payload)                                                  // !!!!!
      // const eventArr = payload.eventArr ?? [];                           // !!!!!
      const limit = 1 //eventArr.length === 0 ? 1 : eventArr.length;        // !!!!!
      console.log('############ limit #################')
      console.log(limit)
      const query = db
        .collection('leaderboard_live')
        .orderBy('modified', 'desc')
        .limit(limit);
        try{
            const snapshot = await query.get();
            snapshot.forEach((doc) => {
                const liveLeaderboard = doc.data();
                leaderboardEventIds.push(doc.id);
                // if(!liveLeaderboard.modified > lastWeek){
                //     return;
                // }
                delete liveLeaderboard.modified;
                leaderboardList.push(...Object.values(liveLeaderboard));
            });

            leaderboardList.forEach((obj) => {
                var indexFun = funLeaderboardList.findIndex(
                    (item) => item.uid === obj.uid
                );
                var indexReward = rewardLeaderboardList.findIndex(
                    (item) => item.uid === obj.uid
                );
                if (indexFun === -1) {
                    funLeaderboardList.push({...obj});
                } else {
                    funLeaderboardList[indexFun].total_points += obj.total_points;
                    funLeaderboardList[indexFun].total_mola_points += obj.total_mola_points;
                    funLeaderboardList[indexFun].correct_outcome += obj.correct_outcome;
                    funLeaderboardList[indexFun].correct_method += obj.correct_method;
                    funLeaderboardList[indexFun].correct_rounds += obj.correct_rounds;
                    funLeaderboardList[indexFun].event_doc_id.push(
                        obj.id.replace('event_', '')
                    );
                }

                if (indexReward === -1 && isDataValidForReward(obj)) {
                    obj.total_points = obj.total_mola_points
                    rewardLeaderboardList.push({...obj});
                }
                
                if (indexReward !== -1 && isDataValidForReward(obj)) {
                  rewardLeaderboardList[indexReward].total_points += obj.total_points;
                  rewardLeaderboardList[indexReward].total_mola_points += obj.total_mola_points;
                  rewardLeaderboardList[indexReward].correct_outcome += obj.correct_outcome;
                  rewardLeaderboardList[indexReward].correct_method += obj.correct_method;
                  rewardLeaderboardList[indexReward].correct_rounds += obj.correct_rounds;
                  rewardLeaderboardList[indexReward].event_doc_id.push(
                    obj.id.replace('event_', '')
                  );
                }
            });
            
            const rankedFunLeaderboard = rankLeaderboard(
              funLeaderboardList
            );
            const rankedRewardLeaderboard = rankLeaderboardReward(
              rewardLeaderboardList
            );
            commit('setState', {
              funLeaderboardWeekly: rankedFunLeaderboard,
              rewardLeaderboardWeekly: rankedRewardLeaderboard,
              leaderboardEventIds: leaderboardEventIds,
            });
        }catch(err){
            console.log(err);
            commit('setState', {
              rewardWeeklyLeaderboardMessage: err.message,
              funWeeklyLeaderboardMessage: err.message,
            });
        }
    },
    async getLeaderboardUserMap({commit}, payload){
      let map = new Map();
      const arrayOfUserIds = [...payload.arr];
      console.log(arrayOfUserIds.length);
      if(arrayOfUserIds.length > 0){
      const loop = Math.ceil(arrayOfUserIds.length / 10);
      console.log(loop)
      const batchSize = 10;
        for(let i = 0; i < loop; i++){
          let batchArray = [];
          batchArray = arrayOfUserIds.slice().splice(i * batchSize, batchSize);
          console.log(i, i * batchSize, batchArray.length, arrayOfUserIds.length);
          if(batchArray.length > 0){
            const users = await db
              .collection('users')
              .where('uid', 'in', batchArray)
              .get();
            try {
              users.forEach((doc) => {
                const obj = doc.data();
                if (!map.has(obj.uid)) {
                  map.set(obj.uid, obj.full_name);
                }
              });
              console.log(map);
              commit('setState', {
                userMap: map,
              });
            } catch (err) {
              console.log(err);
            }
          }
      }
    }else{
      try {
        const allUsers = await db
          .collection('users')
          .get();
        allUsers.forEach((doc) => {
          const obj = doc.data();
          if (!map.has(obj.uid)) {
            map.set(obj.uid, obj.full_name);
          }
        });
        commit('setState', {
          userMap: map,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
},
  getters: {},
};
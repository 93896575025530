const FIREBASE_CONFIG_TEST_ENV = {
  apiKey: "AIzaSyDBIaHX4jYMZnN-ozC412Y9Dl-rqSeDRVA",
  authDomain: "mfp-staging-d4365.firebaseapp.com",
  projectId: "mfp-staging-d4365",
  storageBucket: "mfp-staging-d4365.appspot.com",
  messagingSenderId: "706067780657",
  appId: "1:706067780657:web:033076fc4e4233357d3110",
  measurementId: "G-ZR5M714T6W",
};

const FIREBASE_CONFIG_PROD_ENV = {
  apiKey: "AIzaSyBBIh28RGW4soUNiUO9vUXklcprO8E0Iyo",
  authDomain: "mfp-production-9720b.firebaseapp.com",
  projectId: "mfp-production-9720b",
  storageBucket: "mfp-production-9720b.appspot.com",
  messagingSenderId: "335653463505",
  appId: "1:335653463505:web:61bbf76e4d9b798c506cf0",
  measurementId: "G-PLQYRPW4WV"
};

var FIREBASE_CONFIG = {};

if (process.env.VUE_APP_ENVIRONMENT === "production") {
  console.log("PROD ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_PROD_ENV;
} else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
  console.log("STAGING ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_TEST_ENV;
} else {
  console.log("LOCAL ENVIRONMENT");
  FIREBASE_CONFIG = FIREBASE_CONFIG_TEST_ENV;
}

export default FIREBASE_CONFIG;

import Vue from 'vue';
import VueMeta from 'vue-meta'
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import FIREBASE_CONFIG from './config/firebase';
import firebase from 'firebase/app';
import bus from './bus/index';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/auth';
import VueClipboards from 'vue-clipboards';
import VueScrollTo from 'vue-scrollto';
import VueSocialSharing from 'vue-social-sharing';
import VueCryptojs from 'vue-cryptojs'

Vue.prototype.$bus = bus;
import VueConnectionListener from 'vue-connection-listener';
const connectionListener = new VueConnectionListener(bus);
Vue.use(VueSocialSharing);
Vue.use(VueClipboards);
Vue.use(VueCryptojs);
Vue.use(VueMeta)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 1000,
  easing: 'ease',
  offset: -140,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.config.productionTip = false;
let app = firebase.initializeApp(FIREBASE_CONFIG);
export const db = firebase.firestore();
export const auth = firebase.auth();


  if (app) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
      created() {
        console.log(connectionListener);
        connectionListener.register();
      },
      destroyed() {
        console.log(connectionListener);
        connectionListener.unregister();
      },
    }).$mount('#app');
  }

export const SEASONS = [
  {
    "id": "sr:season:115743",
    "name": "UFC Fight Night: Rozenstruik vs. Gaziev 2024",
    "start_date": "2024-03-02",
    "end_date": "2024-03-03",
    "year": "2024",
    "competition_id": "sr:competition:42279"
  },
  {
    "id": "sr:season:116117",
    "name": "UFC 301: Pantoja vs. Erceg 2024",
    "start_date": "2024-05-04",
    "end_date": "2024-05-05",
    "year": "2024",
    "competition_id": "sr:competition:42335"
  },
  {
    "id": "sr:season:116815",
    "name": "UFC Fight Night: Nicolau vs. Perez 2024",
    "start_date": "2024-04-27",
    "end_date": "2024-04-28",
    "year": "2024",
    "competition_id": "sr:competition:42471"
  },
  {
    "id": "sr:season:117195",
    "name": "UFC Fight Night: Whittaker vs. Chimaev 2024",
    "start_date": "2024-06-22",
    "end_date": "2024-06-23",
    "year": "2024",
    "competition_id": "sr:competition:42563"
  },
  {
    "id": "sr:season:117215",
    "name": "UFC Fight Night: Lewis vs. Nascimento 2024",
    "start_date": "2024-05-11",
    "end_date": "2024-05-12",
    "year": "2024",
    "competition_id": "sr:competition:42577"
  },
  {
    "id": "sr:season:117217",
    "name": "UFC Fight Night: Barboza vs. Murphy 2024",
    "start_date": "2024-05-18",
    "end_date": "2024-05-19",
    "year": "2024",
    "competition_id": "sr:competition:42579"
  },
  {
    "id": "sr:season:117219",
    "name": "UFC 302: Makhachev vs. Poirier 2024",
    "start_date": "2024-06-01",
    "end_date": "2024-06-02",
    "year": "2024",
    "competition_id": "sr:competition:42581"
  },
  {
    "id": "sr:season:117613",
    "name": "UFC 303: McGregor vs. Chandler 2024",
    "start_date": "2024-06-29",
    "end_date": "2024-06-30",
    "year": "2024",
    "competition_id": "sr:competition:42651"
  },
  {
    "id": "sr:season:119467",
    "name": "UFC Fight Night: Perez v Taira 2024",
    "start_date": "2024-06-15",
    "end_date": "2024-06-16",
    "year": "2024",
    "competition_id": "sr:competition:43047"
  },
  {
    "id": "sr:season:118625",
    "name": "UFC 304: Edwards vs. Muhammad 2 2024",
    "start_date": "2024-07-27",
    "end_date": "2024-07-28",
    "year": "2024",
    "competition_id": "sr:competition:42871"
  },
  {
    "id": "sr:season:119233",
    "name": "UFC Fight Night: Cannonier vs Imavov 2024",
    "start_date": "2024-06-08",
    "end_date": "2024-06-09",
    "year": "2024",
    "competition_id": "sr:competition:43017"
  }
]
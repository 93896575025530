<template>
  <v-app>
    <v-overlay
      :value="drawer"
      z-index="4"
    >
    </v-overlay>
    <v-navigation-drawer
      fixed
      v-model="drawer"
      :disable-resize-watcher="true"
      :dark="dark"
      :color="dark ? '#202020' : 'grey lighten-3'"
      :style="isMobileDevice ? 'margin-top: 65px;margin-left: 5px;' : 'margin-top: 85px;margin-left: 10px; overflow-y: visible; height: 75% !important;'"    
      :width="['USER', 'PUBLIC'].includes(userRole) ? '100%' : '320'"
      :height="['USER', 'PUBLIC'].includes(userRole) ? Math.round(0.8 * window.height) : '100%'"
    >
      <v-btn icon @click.stop="drawer = !drawer" :style="`margin: 10px; ${['USER', 'PUBLIC'].includes(userRole) ? 'position:absolute' : ''}`">
        <v-icon>mdi-window-close</v-icon>
      </v-btn>

      <!-- <v-layout v-if="hideNavBar" column align-center pl-2 pr-2 style="margin-top: -20px">
        <v-img
          v-if="!dark"
          style="cursor: pointer"
          @click="routerGo('/')"
          v-scroll-to="'#home'"
          src="/img/logos/superlive_logo.png"
          width="213"
          height="65"
        ></v-img>
        <v-img
          v-if="dark"
          style="cursor: pointer"
          @click="routerGo('/')"
          v-scroll-to="'#home'"
          src="/img/logos/superlive_logo.png"
          width="213"
          height="65"
        ></v-img>
      </v-layout>

      <v-list-item v-if="hideNavBar" style="margin-top: -20px">
        <v-list-item-content>
          <v-list-item-title class="text-h5 text-center pt-4">
            Mola - Fight Predictor
          </v-list-item-title>
          <v-list-item-subtitle> </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider v-if="['USER'].includes(userRole)"></v-divider>
        <v-layout style="margin:10px" v-if="['USER'].includes(userRole) && hideNavBar">
          <v-spacer></v-spacer>
            <v-icon color="#f8df8e">mdi-wallet</v-icon>
            <div :class="dark ? 'pl-2 white--text' : 'pl-2'">
              {{ getUser.mola_points }} {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }}
            </div>
          <v-spacer></v-spacer>
        </v-layout>

       <!-- <v-layout style="margin:10px" v-if="['USER'].includes(userRole)">
          <v-spacer></v-spacer>
            <v-icon small>mdi-wallet-giftcard</v-icon>
            <div :class="dark ? 'pl-2 white--text' : 'pl-2'">
              Redeem Now!
            </div>
          <v-spacer></v-spacer>
        </v-layout> -->
      <v-divider v-if="['USER'].includes(userRole)"></v-divider>

      <!--  <v-divider></v-divider> -->

      <!-- Admin Console Menu  -->
      <v-list v-if="userRole.includes('ADMIN')" dense nav class="ml-2">
       
        <v-list-item v-show="['ADMIN'].includes(userRole)" link @click.stop="routerGo('/admin')">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].ADMIN_DASHBOARD }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN'].includes(userRole)" link @click.stop="openCatalog()" >
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title >{{ lang[getLanguage].TO_MOLA_CATALOG_ADMIN }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group v-show="['ADMIN', 'ADMIN-MIXED'].includes(userRole)" prepend-icon="mdi-sword-cross" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].DUEL_DASHBOARD }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/duel-realtime')">
            <v-list-item-icon>
              <v-icon>mdi-update</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].REAL_TIME_DUEL_MONITORING }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admin/duel-summary')">
            <v-list-item-icon>
              <v-icon>mdi-chart-gantt</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].WEEKLY_DUEL_SUMMARY }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group v-show="['ADMIN', 'ADMIN-MIXED'].includes(userRole)" prepend-icon="mdi-table-account" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PLAYER_INSIGHT_REPORTS }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/weekly-new-player')">
            <v-list-item-icon>
              <v-icon>mdi-account-star</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PLAYER_NEW_REPORT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admin/weekly-points-redemption')">
            <v-list-item-icon>
              <v-icon>mdi-plus-minus-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PLAYER_REDEMPTION_REPORT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group v-show="['ADMIN', 'ADMIN-MIXED'].includes(userRole)" prepend-icon="mdi-boxing-glove" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].GAME_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admevents')">
            <v-list-item-icon>
              <v-icon>mdi-calendar-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].EVENTS_AND_GAMES }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admresults')">
            <v-list-item-icon>
              <v-icon>mdi-calculator-variant-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].GAME_RESULTS }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admnewevent')">
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].CREATE_EVENT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-show="['ADMIN','ADMIN-MIXED'].includes(userRole)" link @click.stop="routerGo('/admin/homepage')">
          <v-list-item-icon>
            <v-icon>mdi-home-edit-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].HOME_MANAGEMENT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN','ADMIN-MIXED'].includes(userRole)" link @click.stop="routerGo('/admin/watch')">
          <v-list-item-icon>
            <v-icon>mdi-television</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].WATCH_MANAGEMENT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN','ADMIN-MIXED'].includes(userRole)" link @click.stop="routerGo('/admin/reward')">
          <v-list-item-icon>
            <v-icon>mdi-trophy-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].REWARD_MANAGEMENT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN', 'ADMIN-NEWS'].includes(userRole)" link @click.stop="routerGo('/admin/news')">
          <v-list-item-icon>
            <v-icon>mdi-newspaper-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ lang[getLanguage].NEWS_MANAGEMENT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN', 'ADMIN-PREDICTION'].includes(userRole)"  link @click.stop="routerGo('/admin/predictionreports')">
            <v-list-item-icon>
              <v-icon>mdi-chart-box-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PREDICTION_ANALYTICS }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-show="['ADMIN', 'ADMIN-PREDICTION'].includes(userRole)"  link @click.stop="routerGo('/admin/predictionwinners')">
            <v-list-item-icon>
              <v-icon>mdi-trophy-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PREDICTION_WINNERS_REPORT }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group v-show="['ADMIN', 'ADMIN-LEADERBOARD','ADMIN-MIXED', 'ADMIN-SUBSCRIBER'].includes(userRole)" prepend-icon="mdi-account-cog-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].ADMIN_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-show="['ADMIN', 'ADMIN-LEADERBOARD','ADMIN-SUBSCRIBER'].includes(userRole)" link @click.stop="routerGo('/leaderboard')">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].LEADERBOARD }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="['ADMIN', 'ADMIN-MIXED'].includes(userRole)"  link @click.stop="routerGo('/admin/recalculate')">
            <v-list-item-icon>
              <v-icon>mdi-printer-pos-wrench-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].POINT_CORRECTION }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="['ADMIN'].includes(userRole)"  link @click.stop="routerGo('/admin/reporting')">
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].REPORTING }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="['martin.kuijs@hotmail.com', 'sabar@mola.tv','inanurulita@mola.tv'].includes(user.email) && ['ADMIN'].includes(userRole)"  link @click.stop="routerGo('/admin/predictionreports')">
            <v-list-item-icon>
              <v-icon>mdi-chart-box-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PREDICTION_ANALYTICS }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-show="['ADMIN', 'ADMIN-SUBSCRIBER'].includes(userRole)"  link @click.stop="routerGo('/admin/predictionwinners')">
            <v-list-item-icon>
              <v-icon>mdi-trophy-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].PREDICTION_WINNERS_REPORT }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

          <v-list-item v-show="['ADMIN', 'ADMIN-SUBSCRIBER'].includes(userRole)"  link @click.stop="routerGo('/admin/subscriberreports')">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].SUBSCRIBER_REPORT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        

          <v-list-item v-show="['ADMIN','ADMIN-MIXED'].includes(userRole)"  link @click.stop="routerGo('/admin/config')">
            <v-list-item-icon>
              <v-icon>mdi-cogs</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].LEADERBOARD_CONFIG }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

       <!-- <v-list-item v-show="['ADMIN'].includes(userRole)" link @click.stop="routerGo('/admin/delete')">
          <v-list-item-icon>
            <v-icon>mdi-delete-alert-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ 'Database Operations' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

         <!-- <v-list-group prepend-icon="mdi-vector-arrange-below" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Prize Catalog</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/admin/prizes')">
            <v-list-item-icon>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Catalog</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group> -->


        <!-- <v-list-group prepend-icon="mdi-vector-link" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].POINTS_AND_TOKEN_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click.stop="routerGo('/adm')">
            <v-list-item-icon>
              <v-icon>mdi-circle-multiple-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].POINTS_MANAGEMENT }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="routerGo('/admresults')">
            <v-list-item-icon>
              <v-icon>mdi-swap-horizontal-bold</v-icon>

            <v-list-item-content>
              <v-list-item-title>{{ lang[getLanguage].SWAP_REQUEST }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group> -->

        <!-- <v-list-item link @click.stop="openCatalog()" v-if="isUserLogin">
          <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mola Catalog</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-layout pa-2 align="center">
          <v-menu v-if="!['USER', 'PUBLIC'].includes(userRole)">
            <template v-slot:activator="{ on, attrs }"
              ><v-btn
                variant="text"
                style="
                  text-transform: none !important;
                  background-color: unset;
                  box-shadow: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 90%
                "
                v-bind="attrs"
                v-on="on"
              > 
                <v-icon small class="mr-md-2" v-if="getLanguage === 'id'">$vuetify.icons.indonesia</v-icon>
                <v-icon small class="mr-md-2" v-if="getLanguage === 'en'">$vuetify.icons.english</v-icon>
                <!-- <v-icon small class="mr-md-2" v-if="getLanguage === 'it'">$vuetify.icons.italy</v-icon> -->
                <span v-if="!isMobileDevice">{{ getLanguage.toUpperCase() }}</span>
                <v-icon right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('id')"
                  ><v-icon small class="mr-2">$vuetify.icons.indonesia</v-icon>BAHASA</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('en')"
                  ><v-icon small class="mr-2">$vuetify.icons.english</v-icon>ENGLISH</v-list-item-title
                >
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('it')"
                  ><v-icon small class="mr-2">$vuetify.icons.italy</v-icon>ITALIAN</v-list-item-title
                >
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-layout>
      </v-list>



      <v-list :style="isMobileDevice ? 'height: ' + window.height  + 'px' : 'height: 75%;overflow-y: visible; '"
        v-if="['USER', 'PUBLIC'].includes(userRole)"
        dense
        nav
        class="ml-2"
      >
      <v-layout column align-center pa-4 style="overflow-y: visible;">
        <v-list-item link @click.stop="routerGo('/')" v-scroll-to="'#home'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].HOME }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/schedule')"  v-scroll-to="'#broadcastschedule'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].PREDICT_NOW_BTN}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/watch_the_match')" v-scroll-to="'#watchmatch'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].WATCH_THE_MATCH}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/leaderboard')" v-scroll-to="'#leaderboard'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-trophy</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].LEADERBOARD }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/howtoplay')" v-scroll-to="'#howtoplay'">
        <!-- <v-list-item link @click.stop="howToPlayDialog = true"> -->
          <!-- <v-list-item-icon>
            <v-icon>mdi-crosshairs-question</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ isValidDateTime() ? lang[getLanguage].HOW_TO_PLAY_CONTEST : lang[getLanguage].HOW_TO_PLAY }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/news')" v-scroll-to="'#news'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].NEWS }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="routerGo('/faq')" v-scroll-to="'#faq'">
          <!-- <v-list-item-icon>
            <v-icon>mdi-frequently-asked-questions</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].FAQ }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.stop="openCatalog()" >
          <!-- <v-list-item-icon>
            <v-icon>mdi-book-open-page-variant-outline</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].MOLA_CATALOG }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isUserLogin"
          link
          @click.stop="routerGo('/myaccount')"
          v-scroll-to="'#accounthome'"
        >
          <!-- <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title style="font-size:1.5rem;line-height: 1.1;">{{ lang[getLanguage].MY_ACCOUNT }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-layout column align-center pa-4 v-if="isUserLogin">
          <v-btn small :dark="dark" outlined @click="signOut" style="width:150px"
            ><v-icon small left>mdi-logout-variant</v-icon>{{ lang[getLanguage].SIGN_OUT }}</v-btn
          >
        </v-layout>

        <!-- <div v-if="isMobileDevice">
          <v-divider></v-divider>

          <v-layout pa-2>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.stop="changeThemeMenu(false)"
              :color="dark ? '#E1F5FE' : '#BDBDBD'"
            >
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="changeThemeMenu(true)"
              :color="dark ? '#000' : '#212121'"
            >
              <v-icon>mdi-moon-waxing-crescent</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout>
        </div> -->
        <v-divider></v-divider>
        <v-layout column align-center px-4>
          <v-menu offset-y v-if="!['USER', 'PUBLIC'].includes(userRole)">
            <template v-slot:activator="{ on, attrs }"
              ><v-btn
                variant="text"
                style="
                  text-transform: none !important;
                  background-color: unset;
                  box-shadow: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 90%
                "
                v-bind="attrs"
                v-on="on"
              > 
                <v-icon small class="mr-md-2" v-if="getLanguage === 'id'">$vuetify.icons.indonesia</v-icon>
                <v-icon small class="mr-md-2" v-if="getLanguage === 'en'">$vuetify.icons.english</v-icon>
                <v-icon small class="mr-md-2" v-if="getLanguage === 'it'">$vuetify.icons.italy</v-icon>
                <span v-if="!isMobileDevice">{{ getLanguage.toUpperCase() }}</span>
                <v-icon right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('id')"
                  ><v-icon small class="mr-2">$vuetify.icons.indonesia</v-icon>BAHASA</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('en')"
                  ><v-icon small class="mr-2">$vuetify.icons.english</v-icon>ENGLISH</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  style="cursor: pointer"
                  @click="changeLanguage('it')"
                  ><v-icon small class="mr-2">$vuetify.icons.italy</v-icon>ITALIAN</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-layout>

        </v-layout>

      </v-list>

      <template v-slot:append>
        <div>
          <v-divider></v-divider>

          <!-- <v-layout pa-2>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click.stop="changeThemeMenu(false)"
              :color="dark ? '#E1F5FE' : '#BDBDBD'"
            >
              <v-icon>mdi-white-balance-sunny</v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="changeThemeMenu(true)"
              :color="dark ? '#000' : '#212121'"
            >
              <v-icon>mdi-moon-waxing-crescent</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-layout> -->
          
        </div>
      </template>

    </v-navigation-drawer>

    <v-app-bar
      :height="isMobileDevice ? 60 : 80"
      app
      :dark="dark"
      :class="dark ? '#202020' : 'grey lighten-3'"
    >
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer" size="50"></v-app-bar-nav-icon> -->
      <v-icon id="menu" :size="isMobileDevice ? 32 : 50" @click="drawer = !drawer" v-if="!drawer">
        mdi-menu
      </v-icon>
      <v-icon id="menu" :size="isMobileDevice ? 32 : 50" @click="drawer = !drawer" v-else>
        mdi-menu-open
      </v-icon>

      <v-img
        v-if="!isMobileDevice"
        src="/img/logos/superlive_x_mola_horizontal.webp"
        style="max-height: 65px; max-width: 320px;cursor: pointer;"
        @click.stop="routerGo('/')"
      ></v-img>

      <v-spacer></v-spacer>

      <v-img
        v-if="isMobileDevice"
        :src="
          dark
            ? '/img/logos/superlive_x_mola_horizontal.webp'
            : '/img/logos/superlive_x_mola_horizontal.webp'
        "
        @click.stop="routerGo('/')"
        style="max-height: 40px; max-width: 200px; cursor: pointer"
      ></v-img>
      <!-- <v-img
        v-if="!isMobileDevice"
        src="/img/logos/ufc_logo.png"
        style="max-height: 35px; max-width: 100px"
      ></v-img> -->

      <v-spacer></v-spacer>

      <v-btn
        target="_blank"
        text
        class="px-1 mx-1"
        @click.stop="showLoginPanel(true)"
        v-show="!isUserLogin"
      >
        <v-icon :small="isMobileDevice">mdi-login</v-icon>
        <span class="mr-2 ml-2">{{ lang[getLanguage].LOGIN }} </span>
      </v-btn>
      
      <div class="d-flex flex-column align-start mt-1 px-2" v-if="isUserLogin && !isMobileDevice">
        <v-img
        :src="
          dark
            ? '/img/icons/mola_points_dark.png'
            : '/img/icons/mola_points_light.png'
        "
        style="max-height: auto; max-width: 25px"
        class="align-self-center"
      ></v-img>
        <div class="font-weight-bold" :class="dark ? 'pl-2 white--text' : 'pl-2'" style="font-size: 10px">
                {{ getUser.mola_points }} {{ lang[getLanguage].MY_ACCOUNT_MOLA_POINTS }}
        </div>
      </div>

      <v-btn icon v-show="isUserLogin" text @click.stop="routerGo('/myaccount')" :class="window.width > 360 ? 'ml-4' : 'mr-4'" v-scroll-to="'#accounthome'">
        <v-icon :size="isMobileDevice ? 32 : 50" v-if="isUserLogin && ['USER', 'PUBLIC'].includes(userRole)" style="height: 50px"
          >mdi-account</v-icon
        >
        <v-icon :size="isMobileDevice ? 32 : 50" v-if="isUserLogin && ['ADMIN', 'ADMIN-NEWS'].includes(userRole)" style="height: 50px"
          >mdi-account-key-outline</v-icon
        >
        <!-- <span v-show="hideNavBar" :class="isMobileDevice ? 'mr-md-2 ml-md-2 mr-1 ml-1 caption' : 'mr-md-2 ml-md-2 mr-1 ml-1'" 
          >Hello, {{ getUser.name }}</span
        > -->
      </v-btn>
      <v-btn v-show="isUserLogin && window.width > 360" text @click.stop="routerGo('/myprofile')" class="px-0" v-scroll-to="'#notification'">
        <v-badge :color="notificationCount > 0 ? 'red' : ''" dot>
          <v-icon :size="isMobileDevice ? 30 : 40"  v-if="isUserLogin && ['USER', 'PUBLIC', 'ADMIN', 'ADMIN-NEWS'].includes(userRole)" style="height: 40px"
            >mdi-bell-ring
          </v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>

    <div id="hometoolbar" v-show="hideNavBar">
      <v-toolbar
        v-if="
          ['USER', 'PUBLIC'].includes(userRole) &&
          $router.currentRoute.path !== '/myprofile' &&
          $router.currentRoute.path !== '/admin/login' &&
          !isMobileDevice
        "
        :style="isMobileDevice ? 'margin-top: 60px' : 'margin-top: 80px'"
        max-height="60"
        color="#f8df8e"
      >
        <v-layout v-show="hideNavBar" v-if="!drawer && !isMobileDevice && !loading">
          <v-btn text @click.stop="routerGo('/')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:1px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].HOME }}</v-btn>
          <span style="margin-top:5px">|</span>
          <v-btn text to="/howtoplay" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">{{ isValidDateTime() ? lang[getLanguage].HOW_TO_PLAY_CONTEST : lang[getLanguage].HOW_TO_PLAY }}</v-btn>
          <span style="margin-top: 5px">|</span>
          <v-btn text @click.stop="openCatalog()" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].MOLA_CATALOG }}</v-btn>
          <span style="margin-top: 5px">|</span>
          <!-- <v-btn text @click.stop="routerGo('/news')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].NEWS }}</v-btn>
          <span style="margin-top: 5px">|</span> -->
          <v-btn text @click.stop="routerGo('/leaderboard')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].LEADERBOARD }}</v-btn>
          <span style="margin-top: 5px">|</span>
          
          <v-btn text @click.stop="routerGo('/faq')" :style="window.width < 1250 ? 'font-size: 0.6rem;margin-left:-5px;padding-left:0px;padding-right:7px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].FAQ }}</v-btn>
          <span style="margin-top: 5px">|</span>

          <v-btn text @click.stop="routerGo('watch_the_match')" :style="window.width < 1250 ? 'font-size: 0.6rem;padding-left:7px;padding-right:7px' : 'font-size: 0.9rem;'">{{ lang[getLanguage].WATCH_THE_MATCH }}</v-btn>

          <!-- <span style="margin-top: 5px">|</span>
          <v-btn text @click.stop="showContactPanel(true)">{{ lang[getLanguage].CONTACT }}</v-btn>
          <span style="margin-top: 5px" v-if="isUserLogin">|</span>
          <v-btn @click="signOut" text v-if="isUserLogin">{{ lang[getLanguage].SIGN_OUT }}</v-btn> -->
        </v-layout>
        <v-layout v-show="hideNavBar" v-else-if="isMobileDevice">
          <v-spacer></v-spacer>
          <!-- <v-img
            src="/img/logos/mola_logo_dark.png"
            style="max-height: 35px; max-width: 110px"
          ></v-img> -->
          <!-- <v-img
            src="/img/logos/ufc_logo.png"
            style="max-height: 35px; max-width: 100px"
          ></v-img> -->
        </v-layout>
        <v-spacer v-show="hideNavBar"></v-spacer>
        <v-btn v-show="hideNavBar" text v-if="window.width > 900 && !getEventListEmpty" :href="liveUrl" target="_blank" :style="window.width < 1250 ? 'font-size: 0.6rem;' : 'font-size: 0.9rem;'"><strong>{{ lang[getLanguage].LIVE_NOW }}</strong></v-btn>
        <v-btn
          v-show="hideNavBar"
          v-if="window.width > 900"
          style="margin-left: 15px"
          dark
          color="#db0019"
          rounded
          :small="window.width >= 1250"
          :x-small="window.width < 1250"
          :style="window.width < 1250 ? 'font-size: 0.6rem;' : 'font-size: 0.9rem;'"
          v-scroll-to="'#broadcastschedule'"
          @click.stop="handlePredict()"
          >{{ lang[getLanguage].HOME_BUTTON }} 
          <v-icon>mdi-arrow-right-thin </v-icon>
        </v-btn>
      </v-toolbar>
    </div>


    <v-main
      :style="`padding-top: ${isMobileDevice ? '60px' : ['/admin/login', '/myprofile'].includes($router.currentRoute.path) ? '80px' : ['USER', 'PUBLIC'].includes(userRole) ? '0px' : '80px'}`" 
    >

      <LoginWindow
        class="mt-3"
        :isOpen="isShowLogin"
        @change="showLoginPanel"
        v-on:fileDialogClicked="openFileDialog"
        @setUser="setUserFromLoginPanel"
        :dark="dark"
        :isMobileDevice="isMobileDevice"
        v-if="isShowLogin"
      ></LoginWindow>
      <Contact
        class="mt-3"
        :isOpen="isShowContact"
        @change="showContactPanel"
        :dark="dark"
        :isMobileDevice="isMobileDevice"
      ></Contact>
      <router-view
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        v-on:loginClicked="showLoginPanel(true)"
        v-on:liveLeaderboardClicked="showLiveLeaderBoard(true)"
        v-on:fileDialogClicked="openFileDialog"
        :windowWidth="window.width"
        :windowHeight="window.height"
      />
      <Footer :isMobileDevice="isMobileDevice"
        @contact="showContactPanel"
        @fileDialogClicked="openFileDialog"
        :dark="dark"
        :drawer="drawer">
      </Footer>
    </v-main>

    <v-dialog
      v-model="dialogFilePreview" :fullscreen="isMobileDevice"
      :width="isMobileDevice ? '100%' : '800'"
      height="auto"
    >
      <v-card>
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="dialogFilePreview = false" icon small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <v-layout column align-center>
          <iframe
            :src="fileName" alt="-"
            :width="isMobileDevice ? '100%' : '800'"
            :height="isMobileDevice ? termsAndPrivacyheight : 700"
          >
          </iframe>
          <p class="mt-3"></p>
        </v-layout>
      </v-card>
    </v-dialog>
    
    <!-- ################### HOW TO PLAY ########################## -->
    <!-- <DuelInvitationDialog
      :isOpen="true"
    ></DuelInvitationDialog> -->

    <v-dialog
      v-model="howToPlayDialog"
      max-width="750"
    >
      <v-card dark style="border-radius: 15px;border: #f8df8e solid 5px;background-color: #404040;">
        <v-card-title :class="isMobileDevice ? 'text-h6' : 'text-h5'" >
          {{ lang[getLanguage].HOW_TO_PLAY }}?
        </v-card-title>
        
        <v-card-text style="text-align: justify;">
          <div style="font-size: 1rem;" v-html="lang[getLanguage].HOW_TO_PLAY_TEXT"></div>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#db0019"
            @click="howToPlayDialog = false"
          >
            {{ lang[getLanguage].CLOSE }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog
      style="border: #CCC 1px solid; position: absolute;right: 10px;"
      :color="dark ? '' : '#CCC'"
      :dark="dark"
      content-class="my-custom-dialog liveDialog"
      :width="isMobileDevice ? '' : '400px'"
      :fullscreen="isMobileDevice"
      :timeout="-1"
      v-model="liveLeaderboard"
      outlined
      shaped
      top
      right
      elevation="24"
    > 
      <v-card 
        :dark="dark" 
        flat
      >
        <v-layout class="px-4">
          <span :class="dark ? 'text-h6 mt-1 font-weight-bold' : 'text-h6 mt-1 font-weight-bold black--text'">{{ lang[getLanguage].LIVE_EVENT_LEADERBOARD}}</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :color="dark? '#FFF' : '#000'"
              @click="closeLiveLeaderboard()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-layout>
        <v-layout column class="px-4">
          <!-- {{ getEventList.filter(Q => Q.doc_id === getLiveLeaderBoardEventId)[0].name }} -->
          <span :class="dark ? 'text-h7 mt-1 font-weight-bold' : 'text-h7 mt-1 font-weight-bold black--text'">{{ getEventList.length > 0 ? getEventList.filter(Q => Q.doc_id === getLiveLeaderBoardEventId)[0]?.name : '' }}</span>
          <span :class="dark ? 'text-h7 mt-1 font-weight-bold' : 'text-h7 mt-1 font-weight-bold black--text'">{{ lang[getLanguage].NEXT_FIGHT_UP }}
            {{ getEventList.length > 0 ? lookupNextFight() : '' }}</span>
        </v-layout>
        <v-layout column align-center v-if="getLiveLeaderboard.length === 0" class="pt-4">
          <v-icon large color="blue">mdi-timer-sand-paused</v-icon>
          <p class="text-center blue--text" v-html="lang[getLanguage].NO_RESULT"></p>
        </v-layout>
        <v-list color="transparent" dense>
          <template v-for="(item, index) in getLiveLeaderboard">
            <v-list-item
              :key="index + 'l'"
              style="height:36px"
            >
              <v-list-item-avatar>
                <v-avatar
                    left
                    style="border-radius:10px;height:30px;color:#FFF"
                    :class="getColorBasedOnRank(item.rank)"
                  >
                  <!-- black--text -->
                  <span class="text-h7 font-weight-black">#{{ item.rank }}</span>
                    
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content class="ml-2">
                <v-list-item-title >
                  <span style="font-size: 14px;" class="font-weight-bold">{{ item.username }}</span>
                </v-list-item-title>
              </v-list-item-content>
              
              <v-list-item-content class="ml-2">
              <v-chip
                  color="light-blue darken-1"
                  label
                  text-color="white"
                  class="px-auto"
                >
                    <v-icon class="ml-4 mr-1" start small>mdi-medal</v-icon>

                  <span class="text-h6 font-weight-bold ">
                    {{ item.total_points }}
                  </span>
                </v-chip>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index + 'a'"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-dialog>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span style="margin-left:10px">{{ snackbarText }}</span>
            <v-btn text color="#388E3C" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-snackbar :timeout="-1" :bottom="'bottom'" v-model="snackbarError">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span style="margin-left:10px">{{ snackbarText }}</span>
      <v-spacer></v-spacer>
    </v-snackbar>

    <!-- <v-snackbar :timeout="-1" :top="'top'" v-model="snackbarReschedule" light>
      <v-icon color="blue">mdi-check-circle-outline</v-icon>
      <span style="margin-left:10px">{{ 'The fight Fighter A Vs. Fighter B has changed, please resumbit your prediction for this fight' }}</span>
            
    </v-snackbar> -->
    
  </v-app>
</template>

<script>
import Contact from './components/shared/Contact.vue';
import { isMobile } from 'mobile-device-detect';
import Footer from './components/shared/Footer.vue';
import { db } from '@/main';
import dateformat from "dateformat"
// import DuelInvitationDialog from './components/prediction/DuelInvitationDialog.vue';
// import { scroller } from 'vue-scrollto/src/scrollTo';
export default {
  name: 'App',
  props: {},
  data: () => ({
    drawer: false,
    dark: true,
    loading: true,
    isMobileDevice: false,
    howToPlayDialog: false,
    hideNavBar: true,
    window: {
      width: 0,
      height: 0,
    },
    isShowLogin: false,
    name: '',
    snackbarText: "",
    snackbarColor: "green",
    snackbarTimeout: 2000,
    snackbar: false,
    snackbarError: false,
    snackbarReschedule: true,
    isOnline: true,
    isShowContact: false,
    liveLeaderboard: false,
    limitLiveLeaderboard: 10,
    dialogFilePreview: false,
    fileName: '',//'http://docs.google.com/gview?url=',
    termsAndPrivacyheight: 22000,
    liveUrl: '',
    watchMatchLink: '',
    logoSrcList: '',
    logoSrcListDark: '',
    notificationCount: 0,
    notificationSnapshot: null,
    windowHeight: 0
  }),
  components: {
    LoginWindow: () => import('./components/login/LoginWindow.vue'),
    Contact,
    Footer,
    // DuelInvitationDialog
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    this.notificationSnapshot()
  },
  beforeMount() {
    this.handleResize();
  },
  async created() {
    console.log("LOCATION", window.location.origin);
    console.log("LOCATION", window.location.host);
    const snapshot = await db.collection('config').doc("homepage_config").get();
    if(snapshot.exists){
      const obj = snapshot.data();
      this.$store.commit('SetState', {
        homeBannerDesktop01: obj.homeBannerDesktop01.url,
        homeBannerDesktopLink01: obj.homeBannerDesktop01.urlLink,
        homeBannerMobile01: obj.homeBannerMobile01.url,
        homeBannerMobileLink01: obj.homeBannerMobile01.urlLink,

        homeBannerDesktop02: obj.homeBannerDesktop02.url,
        homeBannerDesktopLink02: obj.homeBannerDesktop02.urlLink,
        homeBannerMobile02: obj.homeBannerMobile02.url,
        homeBannerMobileLink02: obj.homeBannerMobile02.urlLink,

        homeBannerDesktop03: obj.homeBannerDesktop03.url,
        homeBannerDesktopLink03: obj.homeBannerDesktop03.urlLink,
        homeBannerMobile03: obj.homeBannerMobile03.url,
        homeBannerMobileLink03: obj.homeBannerMobile03.urlLink,
        
        homeTitle: obj.homeTitle,
        homeTagline: obj.homeTagline,
        blankTitle: obj.blankTitle,
        blankTagline: obj.blankTagline,
        carouselInterval: obj.carouselInterval,
        watchMatchLink: obj.watchMatchLink
      })
      this.liveUrl = obj.liveButtonLink;
      this.watchMatchLink = obj.watchMatchLink;
    }

    if (this.user !== null) {
      this.name = this.user.name;
      this.$store.dispatch('getRole');
      this.$store.dispatch('getUserDetail');
      this.$store.dispatch('getMolaSubscription');
      this.getNotificationCount();
      // this.$store.dispatch('getReferralReward');
      // this.$store.dispatch('getUserRecap');
      this.$store.dispatch('getMolaPointsLedger', {uid: this.user.id});
      // console.log('App vue is this happening: getMolaPointsLedger')
      setTimeout(() => {
        this.dark = this.getUser.dark
      }, 1000);
    }
    if (this.role === 1) {
      this.$router.push('/admin');
    }
    this.isMobileDevice = isMobile;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.logoSrcList = '/img/logos/fight.png';
    this.logoSrcListDark = '/img/logos/fight_dark.png';
    console.log('this.getEventList.length')
    console.log(this.getEventList.length)
    setTimeout(() => {
      this.loading = false
    }, 1500);
    setTimeout(() => {
      this.pollLiveLeaderboard()
    }, 10000);
   // if (this.getEventList.length > 0) {
      setInterval(() => {
        console.log(this.getReloadCount)
        console.log('this.getLiveLeaderBoardEventId')
        console.log(this.getLiveLeaderBoardEventId)
        this.$store.commit('setReloadCounter', this.getReloadCount + 1)
        if (this.getLiveLeaderBoardEventId === '') return
        this.pollLiveLeaderboard()
        // send command to broadcast schedule to reload every 10 minutes 60000 * 20
      }, 60000 * 20)
    // }
  },
  computed: {
    lang(){
      return this.$store.state.lang;
    },
    isUserLogin() {
      return this.user !== null;
    },
    user() {
      return this.$store.getters.user;
    },
    getUser() {
      return this.$store.state.user;
    },
    displayName() {
      return this.$store.getters.user === null ? '' : this.user.name;
    },
    role() {
      return this.$store.getters.role;
    },
    userRole() {
      switch (this.role) {
        case 1:
          return 'ADMIN';
        case 2:
          return 'ADMIN-NEWS';
        case 3:
          return 'ADMIN-LEADERBOARD';
        case 4:
          return 'ADMIN-PREDICTION';
        case 5:
          return 'ADMIN-MIXED';
        case 6:
          return 'ADMIN-SUBSCRIBER';
        case 10:
          return 'USER';
        default:
          return 'PUBLIC';
      }
    },
    getEventList () {
      return this.$store.state.fb.eventList
    },
    getEventListEmpty () {
      return this.$store.state.fb.eventListEmpty
    },
    getLiveLeaderboard () {
      return this.$store.state.fb.liveLeaderboard
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    getLiveLeaderBoardEventId () {
      return this.$store.state.liveLeaderBoardEventId
    },
    getReloadCount () {
      return this.$store.state.reloadCount
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.name = value.name;
        this.$store.dispatch('getRole')
        this.$store.dispatch('getUserDetail')
        this.getNotificationCount();
        // this.$store.dispatch('getUserRecap');
        this.$store.dispatch('getMolaSubscription')
        this.$store.dispatch('getMolaPointsLedger', {uid: this.user.id})
        setTimeout(() => {
          this.dark = this.getUser.dark
        }, 1000);
      }
    },
    // role(value){
    //   if(value === 1){
    //     this.$router.push('/admin');
    //   }
    // },
    displayName(value) {
      console.log(value);
      this.name = value;
    },
  },
  mounted() {
    console.log("mounted");
    this.connection();
  },
  methods: {
    routerGo(route) {
      /* if (this.isMobileDevice) {
        console.log("this.drawer = false");
        setTimeout(() => {
          this.drawer = false;
        }, 500);
      } */
      // console.log(this.$router.currentRoute.path)
      // console.log(route)
      this.drawer = false;
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
    },
    pollLiveLeaderboard () {
      this.loadingData = true
      let event_doc_id = this.getLiveLeaderBoardEventId // 'NO1137uyMolZZokojb97' // from events that are live. this.getEventList if checkFirstFightHasStarted till checkLastFightHasEnded Ednded
      let dispatchObj = {
        limit: this.limitLiveLeaderboard,
        loadMore: false,
        eventId: event_doc_id,
        current_arr: this.getLiveLeaderboard
      }
      console.log(dispatchObj)
      this.$store.dispatch('getLiveLeaderboard', dispatchObj)
        .then(() => {
          this.loadingData = false
          console.log('Get Live Leaderboard from Firebase')
          console.log(this.getLiveLeaderboard)
        })
        .catch(error => {
          console.log(error)
          this.loadingData = false
        })
    },
    lookupNextFight () {
      // console.log('next fight lookup')
      if (this.getLiveLeaderBoardEventId === '') return
      let event_doc_id =  this.getLiveLeaderBoardEventId
      let item = this.getEventList.find(item => item.doc_id === event_doc_id)
      console.log(item.fights)
      if (item === '' || item.fights.length === 0) return
      let fight = {}
      let ended = false
      for (var f = item.fights.length - 1; f >= 0; f--) {
        if (item.fights[f].results_processed === 0 && item.fights[f].status === 1) {
          fight = item.fights[f]
          console.log(item.fights[f])
          ended = false
          break;
        } else {
          ended = true
        }
      }
      console.log('###### ended #########')
      console.log(ended)
      return ended ? this.lang[this.getLanguage].EVENT_END : this.makeName(fight.home.name) + ' Vs. ' + this.makeName(fight.away.name)
    },
    makeName (name) {
      // console.log('name.indexOf(',') ===  -1')
      // console.log(name.indexOf(',') ===  -1)
      if (name.indexOf(',') ===  -1) return name
      name.replace(' ', '')
      // console.log(name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0])
      return name.replace(' ', '').split(',')[1] + ' ' + name.split(',')[0]
    },
    getColorBasedOnRank(value){
      switch(value){
        case 1:
          return 'my-2 green darken-4';
        case 2:
          return 'my-2 light-green darken-3';
        case 3:
          return 'my-2 light-green darken-1';
        default:
          return 'my-2 blue darken-4';
      }
    },
    changeThemeMenu(dark) {
      this.dark = dark;
      this.$store.commit('SetTheme', {
        dark: this.dark,
      });
      // Update firebase when logged in
      let obj = {
        dark: this.dark,
      };
      if (this.isUserLogin) {
        db.collection('users')
        .doc(this.user.id)
        .update(obj)
        .then(() => {
          console.log('User Account in bucket updated');
          // Snackbar That confirms
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      // console.log(window.innerWidth)
      // this.window.width > 770 ? this.drawer = false : this.drawer = true
    },
    handlePredict() {
      if (!this.user) {
        this.showLoginPanel(true);
      } else {
        this.$router.push('/schedule');
      }
    },
    showLoginPanel(value) {
      this.isShowLogin = value;
    },
    showLiveLeaderBoard(value) {
      this.pollLiveLeaderboard()
      this.liveLeaderboard = value;
    },
    closeLiveLeaderboard () {
      this.$store.commit('setLiveLeaderbordEventId', '')
      this.liveLeaderboard = false
    },
    showContactPanel(value) {
      console.log(value);
      this.isShowContact = value;
    },
    setUserFromLoginPanel(value) {
      this.user = value;
    },
    logout() {
      this.$store.dispatch('logout');
    },
    getWeek () {
      return dateformat(new Date(), 'WW')
    },
    getNotificationCount(){
      console.log(this.user.id);
      this.notificationSnapshot = db.collection('notifications')
        .where("receiver_uid", "==", this.user.id)
        .where("status", "==", 0)
        .onSnapshot(item => {
          console.log("NOTIF", item)
          this.notificationCount = item.size
        })
    },
    connection() {
      this.$bus.$on("connection", (online) => {
        if(online){
          this.snackbarError = false;
          this.snackbar = true;
          this.snackbarText = "You are online";
        }else{
          this.snackbar = false;
          this.snackbarError = true;
          this.snackbarText = "You are offline";
        }
      });
    },
    changeLanguage(lang){
      this.$store.commit('SetCurrentLanguage', lang)

       // Update firebase when logged in
       let obj = {
        language: lang,
      };
      if (this.isUserLogin) {
        db.collection('users')
        .doc(this.user.id)
        .update(obj)
        .then(() => {
          console.log('User Account in bucket updated');
          // Snackbar That confirms
          this.$store.commit('SetUserState', {language: lang})
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    openFileDialog(type){
      let fileName = ''

      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("PRODUCTION ENVIRONMENT");
        fileName = process.env.VUE_APP_MFP;
      }
      else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("TEST ENVIRONMENT");
        fileName = process.env.VUE_APP_MFP_TEST;
      } 
      else{
        console.log("LOCAL ENVIRONMENT");
        fileName = process.env.VUE_APP_MFP_LOCAL;
      }
      if(type === 'tnc'){
        this.termsAndPrivacyheight = 22000
        //Terms_and_conditions_202407_id.html
        fileName += 'legal/Terms_and_conditions_202407_' //if the file is intact we can use ${this.getLanguage}
      }
      if(type === 'pp'){
        this.termsAndPrivacyheight = 7000
        fileName += 'legal/Privacy_Policy_202406_'
      }
      switch(this.getLanguage){
        case 'id':
          this.fileName = fileName + 'id.html';//&embedded=true" style="width:600px; height:500px;';
          break;
        case 'en':
          this.fileName = fileName + 'en.html'
          // this.fileName = fileName + 'en.pdf';//&embedded=true" style="width:600px; height:500px;';
          break;
        case 'it':
          this.fileName = fileName + 'it.html';//&embedded=true" style="width:600px; height:500px;';
          break;
        default:
          this.fileName = fileName + 'id.html';//&embedded=true" style="width:600px; height:500px;';
          break;
      }
      console.log(this.fileName);
      this.dialogFilePreview = true;
    },
    openWatchMola() {
      window.open(this.watchMatchLink);
    },
    openCatalog () {
      if (!this.isUserLogin) {
        window.open('https://catalog.moladigital.com/auth/bG9nb3V0/bm90bG9nZ2VkaW4=',"_self");
        return
      }
      let userObj = {
        email: this.getUser.email,
        name: this.getUser.name,
        souce: 'mfp-fight',
        mfp_fight_uid: this.getUser.uid,
        deviceId: this.getUser.deviceId,
        language: this.getUser.language
      }
      // console.log(userObj)
      let objEncypt = this.$CryptoJS.AES.encrypt(JSON.stringify(userObj), "bamboozle").toString()
      objEncypt = objEncypt.replace(/\//g,'-')
      // console.log(objEncypt)

      var CATALOG_APP;
      if (process.env.VUE_APP_ENVIRONMENT === "production") {
        console.log("PRODUCTION ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG;
      }
      else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
        console.log("TEST ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_TEST;
      } 
      else{
        console.log("LOCAL ENVIRONMENT");
        CATALOG_APP = process.env.VUE_APP_CATALOG_LOCAL;
      }
      var token =
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12)
      var link = CATALOG_APP + 'auth/' + objEncypt + '/' + token
      window.open(link,"_self");
      /* objEncypt = objEncypt.replaceAll('-', '/')
      console.log(objEncypt)
      const decryptedText = this.$CryptoJS.AES.decrypt(objEncypt, "bamboozle").toString(this.$CryptoJS.enc.Utf8)
      console.log(JSON.parse(decryptedText)) */
    },
    signOut() {
      this.$store.dispatch('logout').then(() => {
        // const firstScrollTo = scroller();
        // console.log('Clear userPredictionsArr Array')
        // this.$store.dispatch('clearUserPredictions')
        setTimeout(() => {
          this.$router.push('/');
          window.location.reload();
          // firstScrollTo('#hometoolbar');
        }, 1000);
      });
    },
    isValidDateTime() {
      const jakartaTimeZone = 'Asia/Jakarta';

      const startDateTimeJakarta = new Date('2024-06-01T00:00:00+07:00');
      const endDateTimeJakarta = new Date('2024-06-30T00:00:00+07:00');

      const startDateTimeUTC = new Date(startDateTimeJakarta.toISOString());
      const endDateTimeUTC = new Date(endDateTimeJakarta.toISOString());

      const now = new Date();
      const currentDateTimeJakarta = new Date(now.toLocaleString('en-US', { timeZone: jakartaTimeZone }));
      
      const currentDateTimeUTC = new Date(currentDateTimeJakarta.toISOString());
      // console.log('isValidDateTime', currentDateTimeUTC >= startDateTimeUTC);
      // console.log('isValidDateTime', currentDateTimeUTC < endDateTimeUTC);

      return currentDateTimeUTC >= startDateTimeUTC && currentDateTimeUTC < endDateTimeUTC;
    }
  },
};
</script>
<style>
.my-custom-dialog {
  align-self: flex-end;
}

.liveDialog {
    position: absolute;
    top: 0;
    right: 0;
}


#menu.v-icon.v-icon::after {
    background-color: transparent !important;
}
</style>

import { db } from '../main';
import dateformat from 'dateformat';
function makeDate(date){
    return dateformat(new Date(date), 'dd mmm, yyyy');
}
function makeDateTime(date) {
  return dateformat(new Date(date), 'dd mmm, yyyy HH:MM');
}
export default {
  state: {
    lastEventTitle: '',
    lastEventId: '',
    lastEventPointArr: [],
    historyPointArr: [],
    pointErrorMessage: '',
    pointLoading: false,
    eventHistory: null,
    historyErrorMessage: '',
    historyLoading: false,
    lastHistory: null,
    stopLoadMoreHistory: false,
    totalPointsWonLastEvent: 0,
    userSwapRequestsArr: [],
    userSwapRequestsEmpty: false,
    pointsWonForEvent: [],
    pointsWonForEventEmpty: false,
    pointsReddemedForEventWeek: [],
    pointsReddemedForEventWeekEmpty: false
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
    setTotalPointsWonLastEvent (state, payload) {
      state.totalPointsWonLastEvent = payload
    },
    setUserSwapRequestsArr (state, payload) {
      state.userSwapRequestsArr = payload
    }, 
    setUserSwapRequestsEmpty (state, payload) {
      state.userSwapRequestsEmpty = payload
    }
  },
  actions: { 
    getLastEventPoint({ commit }, payload) {
      console.log("get last event point", payload)
      commit('setState', {
        pointLoading: true,
      });
      let lastEventPoints = [];
      let totalPointsWon = 0
      let eventId = '';
      let eventName = '';
      let eventMap = {};
      let query = db
        .collection('events')
        .where('results_processed', '>', 0)
        .orderBy('results_processed', 'desc')
        .limit(1); //Change next week
      query
        .get()
        .then((snapshot) => {
          console.log(snapshot)
          if (snapshot.empty) {
            commit('setState', {
              pointLoading: false,
              pointErrorMessage: 'No Point History Found',
            });
            return;
          }
          snapshot.docs.forEach(doc => {
            console.log(doc.id);
            eventId = doc.id;
            eventName = doc.data().name;
            eventMap[eventId] = eventName;
            let queryPredictions = db
              .collection('predictions')
              .where('event_doc_id', '==', eventId)
              .where('uid', '==', payload.user_id);
            queryPredictions
              .get()
              .then((snapshot) => {
                console.log(snapshot)
                if (snapshot.empty) {
                  commit('setState', {
                    pointLoading: false,
                    pointErrorMessage: 'No Point History Found',
                  });
                  return;
                }
                snapshot.forEach((doc) => {
                  const pointData = doc.data();
                  console.log(pointData)
                  if (pointData.results_processed > 0) {
                    lastEventPoints.push({
                      date: makeDate(pointData.results_processed),
                      event: eventMap[pointData.event_doc_id],
                      fight: pointData.p_game_name ?? '',
                      mola_sub_type: pointData.mola_sub_type,
                      outcome: pointData.points_won_outcome ?? 0,
                      method: pointData.points_won_method ?? 0,
                      rounds: pointData.points_won_rounds ?? 0,
                      total_point: pointData.points_won,
                      is_duel: pointData.is_duel ?? false,
                    });
                  }
                  totalPointsWon += pointData.points_won
                });
                const statePayload = {
                  lastEventTitle: '',
                  lastEventId: '',
                  lastEventPointArr: lastEventPoints,
                  pointLoading: false,
                };
                console.log(statePayload);
                commit('setTotalPointsWonLastEvent', totalPointsWon)
                commit('setState', statePayload);
              })
              .catch((err) => {
                console.log(err);
                commit('setState', {
                  pointLoading: false,
                  pointErrorMessage:
                    err.message ?? 'Error! Something Went Wrong!',
                });
              });
          })
        })
        .catch((err) => {
          console.log(err);
          commit('setState', {
            pointLoading: false,
            pointErrorMessage: err.message ?? 'Error! Something Went Wrong!',
          });
        });
    },
    async getPointHistory({commit, state}, payload){
      commit('setState', {
        historyLoading: true,
      });
      let historyEventPoints = state.historyPointArr;
      // if (historyEventPoints.length === 0) {
      //   historyEventPoints = [...state.lastEventPointArr];
      //   console.log(historyEventPoints);
      //   console.log('before 2', historyEventPoints.length);
      // }
      let mapEvent = new Map();
      console.log(state.eventHistory)
      if (state.eventHistory === null) {
        let query = db
          .collection('events')
          .where('results_processed', '>', 0)
          .orderBy('results_processed', 'desc');

        const eventSnapshot = await query.get();
        if(!eventSnapshot.empty)
        {
            eventSnapshot.forEach((doc) => {
              const eventData = doc.data();
              console.log(eventData);
              mapEvent.set(doc.id, eventData);
            });
            commit('setState', {
              eventHistory: mapEvent,
            });
        }
      } else {
        mapEvent = state.eventHistory;
      }
      console.log(mapEvent);
      if (mapEvent.size !== 0) {
        let keys = [...mapEvent.keys()];
        const index = keys.indexOf(state.lastEventId);
        if (index > -1) {
          keys.splice(index, 1);
        }
        console.log(keys)
        if(keys.length === 0){
          commit('setState', {
            historyLoading: false,
            stopLoadMoreHistory: true,
          });
          return;
        }
        let historyQuery;
        if ([null, undefined].includes(state.lastHistory)) {
          historyQuery = db
            .collection('predictions')
            // .where('event_doc_id', 'in', keys)
            .where('uid', '==', payload.user_id)
            .where('results_processed', ">", 0)
            .orderBy('results_processed', 'desc')
            .orderBy('created', 'desc')
            .limit(payload.limit);
        } else {
          historyQuery = db
            .collection('predictions')
            // .where('event_doc_id', 'in', keys)
            .where('uid', '==', payload.user_id)
            .where('results_processed', '>', 0)
            .orderBy('results_processed', 'desc')
            .orderBy('created', 'desc')
            .startAfter(state.lastHistory)
            .limit(payload.limit);
        }
        console.log(keys)
        historyQuery
          .get()
          .then((snapshot) => {
            let stopLoadMore = false;
            const lastVisibleHistory = snapshot.docs[snapshot.docs.length - 1];
            console.log("Last", lastVisibleHistory);
            if([undefined, null].includes(lastVisibleHistory)){
                stopLoadMore = true;
            }
            snapshot.forEach((doc) => {
              const pointData = doc.data();
              console.log(pointData);
              console.log(pointData.results_processed);
              if (pointData.results_processed >= 0) {
                console.log(mapEvent.get(pointData.event_doc_id));
                historyEventPoints.push({
                  date: makeDateTime(pointData.results_processed),
                  event: mapEvent.get(pointData.event_doc_id).name,
                  endAt: makeDateTime(mapEvent.get(pointData.event_doc_id).event_end),
                  startAt: mapEvent.get(pointData.event_doc_id).event_start,
                  startAt2: makeDateTime(mapEvent.get(pointData.event_doc_id).event_start),
                  fight: pointData.p_game_name ?? '',
                  mola_sub_type: pointData.mola_sub_type,
                  outcome: pointData.points_won_outcome ?? 0,
                  method: pointData.points_won_method ?? 0,
                  rounds: pointData.points_won_rounds ?? 0,
                  total_point: pointData.points_won,
                });
              }
            });
            // console.log(historyEventPoints);
            // console.log('after',historyEventPoints.length);
            // console.log('stop load', stopLoadMore)
            if(historyEventPoints.length > 0){
              historyEventPoints.sort((a,b) => {
                return new Date(b.startAt) - new Date(a.startAt)
              })
            }
            console.log(JSON.stringify(historyEventPoints));
            commit('setState', {
              historyPointArr: historyEventPoints,
              lastHistory: lastVisibleHistory,
              historyLoading: false,
              stopLoadMoreHistory: stopLoadMore
            });
          })
          .catch((err) => {
            console.log(err);
            commit('setState', {
              historyLoading: false,
              stopLoadMoreHistory: true,
              historyErrorMessage:
                err.message ?? 'Error! Something Went Wrong!',
            });
          });
      }
      else{
        commit('setState', {
          historyLoading: false,
          stopLoadMoreHistory: true,
        });
      }
    },
    async getPointsWonForEvent ({commit}, payload){
      let pointsWon = [];
      let queryPointsWon = db
        .collection('points')
        .where('eid', '==', payload.event_doc_id)
        .where('status', '==', 1)

      if (payload.isDateRange) {
        queryPointsWon = db
        .collection('points')
        .where('transaction_timestamp', '>=', payload.week_start)
        .where('transaction_timestamp', '<=', payload.week_end)
        .where('status', '==', 1)
        .where('type', 'in', [1,5,6])
      }
        try {
          const pointsWonSnapshot = await queryPointsWon.get();
          if (!pointsWonSnapshot.empty) {
            pointsWonSnapshot.forEach((doc) => {
              const pointsWonData = doc.data();
              pointsWon.push(pointsWonData);
            });
            commit('setState', {
              pointsWonForEvent: pointsWon,
            });
          }
        } catch (Err) {
          console.log(Err);
        }
    },
    async getPointsRedeemedRange  ({commit}, payload){
      let pointsWon = [];
      let queryPointsWon = db
        .collection('points')
        .where('transaction_timestamp', '>=', payload.week_start)
        .where('transaction_timestamp', '<=', payload.week_end)
        .where('status', '==', 1)
        .where('type', 'in', [2,4])
        try {
          const pointsWonSnapshot = await queryPointsWon.get();
          if (!pointsWonSnapshot.empty) {
            pointsWonSnapshot.forEach((doc) => {
              const pointsWonData = doc.data();
              pointsWon.push(pointsWonData);
            });
            commit('setState', {
              pointsReddemedForEventWeek: pointsWon,
            });
          }
        } catch (Err) {
          console.log(Err);
        }
    },
    // ###############################################################
    // ###################### SWAP REQUESTS ##########################
    // ###############################################################
    loadSwapRequests ({commit}, payload) {
      commit('setLoading', true)
      let today = new Date().getTime()
        let query = db.collection('swaprequests').where('uid', '==', payload.uid).orderBy('created', 'desc').limit(payload.limit)
        query.get()
            .then(snapshot => {
            if (snapshot.empty) {
                console.log('No matching Swap Requests.')
                commit('setUserSwapRequestsEmpty', true)
                return
            }
            var swapRequestList = []
            snapshot.forEach(doc => {
                console.log(doc.id, '=>', doc.data())
                var obj = doc.data()
                obj.checkTime = Math.round(today / 1000 + 1800) // 1 Hour to prevent to much Firebase loading/reading
                swapRequestList.push(obj)
                // var inArray = eventList.findIndex(item => item.id === obj.id) > -1
            })
            console.log('##### Predictions List loaded ######')
            console.log(swapRequestList)
            commit('setUserSwapRequestsEmpty', false)
            commit('setUserSwapRequestsArr', swapRequestList)
        }, err => {
            console.log(`Encountered error: ${err}`)
        })
    },
    createSwapRequest ({commit}, payload) {
      commit('setLoading', true)
      db.collection('swaprequests').doc(payload.id).set(payload)
      .then(() => {
        console.log('Swap Request in bucket created.')
      })
      .catch(error => {
        console.log(error)
      })
    },
    cancelSwapRequest ({commit}, payload) {
      commit('setLoading', true)
      db.collection('swaprequests').doc(payload.id).update(payload)
      .then(() => {
        console.log('Swap Request in bucket cancelled.')
      })
      .catch(error => {
        console.log(error)
      })
    },
    async referralRewardToMolaPoints ({commit, dispatch}, payload) {
      commit('setLoading', true)
      let uid_1 = payload.uid_receiver
      let rewardPoints = 5
      const doc = await db.collection('user_referrals').where('code' ,'==', payload.referral_code).limit(1).get()  
      console.log(doc)
      if (doc.size > 0) {
        let uid_2 = doc.docs[0].id
        let name_2 = doc.docs[0].data().user_name
        this.referralDocArr =  doc.docs[0].data().referrals
        let index = doc.docs[0].data().referrals.findIndex(item => item.uid === uid_1)
        this.referralDocArr[index].rewarded = true
        this.referralDocArr[index].rewarded_time = new Date().getTime()
        this.referralDocArr[index].points = rewardPoints
        console.log(this.referralDocArr)

        // Insert Mola Points
        await dispatch('insertRewardsPoints', {
          uid: uid_1,
          type_text: 'Sign Up Reward Credit',
        });
        await dispatch('insertRewardsPoints', {
          uid: uid_2,
          type_text: 'Referral Reward Credit',
        });

        // Insert Notification
        await dispatch('insertRewardsNotification', {
          uid: uid_1,
          name: payload.uid_receiver_name,
          type_text_id: 'Daftar Kredit Hadiah',
          type_text_en: 'Sign Up Reward Credit',
          type_text_it: 'Credito premio per l\'iscrizione',
          type_msg_id: 'Anda menerima hadiah Pendaftaran MFP sebesar ' + rewardPoints + ' Poin Mola',
          type_msg_en: 'You received a MFP Sign Up reward of ' + rewardPoints + ' Mola Points',
          type_msg_it: 'Hai ricevuto un premio di iscrizione alla MFP di ' + rewardPoints + ' Mola Punti',
        });
        await dispatch('insertRewardsNotification', {
          uid: uid_2,
          name: name_2,
          type_text_id: 'Kredit Hadiah Undang',
          type_text_en: 'Referral Reward Credit',
          type_text_it: 'Credito premio per referral',
          type_msg_id: payload.uid_receiver_name + ' bergabung dengan MFP dan Anda menerima '+rewardPoints +' Poin Mola',
          type_msg_en: payload.uid_receiver_name + ' joined MFP and you received ' + rewardPoints + ' Mola Points',
          type_msg_it: payload.uid_receiver_name + ' ti sei iscritto a MFP e hai ricevuto ' + rewardPoints + ' Mola Punti',
        });

        let referralObj = {
          modified: new Date().getTime(),
          referrals: this.referralDocArr
        }
        db.collection('user_referrals')
          .doc(uid_2)
          .update(referralObj)
          .then(() => {
            console.log('Referral for users in bucket updated');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // ##################################################################################
    // ##################### Insert Points Ledger Operation #############################
    // ##################################################################################
    insertRewardsPoints({ commit }, payload) {
      commit('setLoading', true);
      var tx_id =
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12) +
        Math.random().toString(36).substring(2, 12);
      const pointsObj = {
        tx_id: '0x' + tx_id,
        uid: payload.uid,
        points: 5, //payload.points_won,
        type: 1, // 1: prediction game credit, 2:redeem points product debit, 3:swap to token debit, 4:refund)
        type_text: payload.type_text, // 'Referral Reward Credit',
        fid: '', // payload.fight_doc_id,
        eid: '', // payload.event_doc_id,
        transaction_timestamp: new Date().getTime(),
        status: 1, // 1:completed, 2:pending, 3:cancelled)
      };
      console.log(pointsObj);
      let postkey = db.collection('points').doc();
      db.collection('points')
        .doc(postkey.id)
        .set(pointsObj)
        .then(() => {
          console.log('Points in ledger created');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    insertRewardsNotification({ commit }, payload) {
      commit('setLoading', true)
      let postkey = db.collection('notifications').doc();
      const notification = {
        id: postkey.id,
        type: 'referral', //duel_invitation, referral
        sender_uid: 0, //if from admin can be 0
        sender_username: 'Admin', //if from admin can be just 'Admin' / 'MFP'
        receiver_uid: payload.uid, // USER ID
        receiver_username: payload.name, // USER USERNAME
        title: {
          id: payload.type_text_id,
          en: payload.type_text_en,
          it: payload.type_text_it
        }, // TITLE 3 language
        message: {
          id: payload.type_msg_id,
          en: payload.type_msg_en,
          it: payload.type_msg_it
        }, // MESSAGE 3 language
        actions: [], // if you don't want to have button, just empty arraay []
        created_by: 'Admin', // admin name
        created: new Date().getTime(), // time
        modified_by: '', // empty
        modified: 0, // 0
        status: 0 // 0: Sent, 1: Read, 2: Action is already clicked
      }
      db.collection('notifications')
        .doc(postkey.id)
        .set(notification)
        .then(() => {
          console.log('Notification created');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  getters: {
  },
};